import { useCallback } from "react"
import { useAxiosInstances } from "../axios-instance"
import { INaturezaJuridica } from "../../application/models/INaturezaJuridica"

export const useNaturezaJuridicaApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const findAllNaturezaJuridica = useCallback((): Promise<INaturezaJuridica[]> => {
    return axiosAuthInstance.get('/natureza-juridica/all').then((response) => {
      const result = response.data?.result?.naturezaJuridicaList
      if(result) {
        return result
      } else {
        throw new Error('Erro ao buscar naturezaJuridica')
      }
    }).catch((error) => {
      throw error
    })
  }, [axiosAuthInstance])
  return {
    findAllNaturezaJuridica
  }
}