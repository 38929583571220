import { Box, Button, Dialog, DialogTitle, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemText, Switch, TextField, Typography } from "@mui/material"
import { FilterHeader } from "../filter-header"
import { MapContainer, Polygon, TileLayer, useMap } from "react-leaflet";
import { Icon } from 'leaflet'
import markerIcon from '../../../../../assets/img/marker-icon.png'
import { useGeolocationService } from "../../../../../../use-cases/geolocation";
import { useAppSelector } from "../../../../../../application/states/hooks";
import { useEffect, useRef, useState } from "react";
import { theme } from "../../../../../styles";
import { darkenColor, GetGeolocationTypeLabel, getZoomLevel, onlyNumbers } from "../../../../../../utils";
import { Close, Search, AccountBalance, FlagCircle, LocationCity, NaturePeople, PushPin, Signpost, SouthAmerica, Traffic, MyLocation } from "@mui/icons-material";
import L from "leaflet";
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { useProjectService } from "../../../../../../use-cases/project";

export const GeolocationFilter = () => {
    // Service
    const {
        findGeolocation,
        setSearchTerm,
        setSelectedGeolocation,
        setGeolocationRadius,
        setGeolocationOpen,
        findGeolocationByLatLon
    } = useGeolocationService()

    const { setUseGeolocationFilter } = useProjectService()
    // State
    const { searchTerm, geolocationsFound, selectedGeolocation, radius, open } = useAppSelector((state) => state.geolocation)
    const { selectedFilter } = useAppSelector((state) => state.project)
    const [latLon, setLatLon] = useState<[number, number] | null>(null)
    const circleRef = useRef<any>(null);
    const markerRef = useRef<any>(null);

    useEffect(() => {
        if (selectedFilter?.geolocation) {
            if (selectedGeolocation) {
                setLatLon([
                    selectedGeolocation.lat,
                    selectedGeolocation.lon
                ])
            }
        }

    }, [selectedFilter?.geolocation, selectedGeolocation])

    const ResetCenterView = (props: any) => {
        const latLon = props.latLon
        const map = useMap()
        const isInitialMount = useRef(true)

        map.pm.setLang('pt_br', {
            actions: {
                finish: 'Salvar alterações',
                cancel: 'Cancelar'
            },
            buttonTitles: {
                editButton: 'Editar'
            },
            measurements: {
                area: 'Área',
                coordinates: 'Coordenadas',
                height: 'Altura',
                perimeter: 'Perímetro',
                radius: 'Raio',
                width: 'Comprimento'
            }
        })

        map.pm.addControls({
            position: 'topright',
            drawCircleMarker: false,
            rotateMode: false,
            customControls: true,
            cutPolygon: false,
            dragMode: false,
            drawCircle: false,
            drawControls: false,
            drawMarker: false,
            drawPolygon: false,
            drawPolyline: false,
            drawRectangle: false,
            drawText: false,
            removalMode: false,
            snappingOption: false,
            editControls: true,
            editMode: true
        })

        map.pm.setGlobalOptions({
            snappable: false,
            markerEditable: false,
            minRadiusCircle: 1000,
            resizableCircle: true,
            pathOptions: {
                color: theme.palette.primary.main
            },
            allowSelfIntersection: false
        })

        map.pm.disableDraw('Polygon');
        map.pm.disableGlobalEditMode();

        useEffect(() => {
            if (isInitialMount.current) {
                isInitialMount.current = false;
            } else {
                if (selectedGeolocation) {
                    if (latLon) {
                        if (circleRef.current) {
                            circleRef.current.remove();
                        }
                        if (markerRef.current) {
                            markerRef.current.remove();
                        }

                        const circle = L.circle(
                            latLon,
                            {
                                pmIgnore: false,
                                radius: radius,
                                color: theme.palette.primary.main
                            }
                        )

                        circle.addTo(map)
                        circleRef.current = circle;

                        const marker = L.marker(
                            latLon,
                            {
                                pmIgnore: false,
                                icon: new Icon({
                                    iconUrl: markerIcon,
                                    iconSize: [40, 40]
                                }),
                                draggable: false
                            }
                        )

                        marker.addTo(map)
                        markerRef.current = marker;

                        circle.on('click', (e) => {
                            const divideNumber = (e.target._mRadius / 1000)
                            const roundNumber = Math.round(divideNumber)

                            const multiplyNumber = (roundNumber * 1000)

                            circle.bindPopup(
                                `<h2><strong>Raio:</strong> ${(multiplyNumber / 1000).toLocaleString('pt-BR')} km </h2>`
                            ).openPopup()
                        })

                        circle.on('pm:enable', (e) => {
                            const paths = document.querySelectorAll('.leaflet-interactive');
                            paths.forEach(path => {
                                path.setAttribute('stroke', theme.palette.primary.main);
                            })

                            const markerIcon = document.querySelectorAll('.marker-icon') as NodeListOf<HTMLElement>
                            markerIcon.forEach(icon => {
                                const currentStyle = window.getComputedStyle(icon);
                                const currentBorderStyle = currentStyle.borderStyle;
                                const currentBorderWidth = currentStyle.borderWidth;

                                icon.style.borderColor = theme.palette.primary.main;
                                icon.style.borderStyle = currentBorderStyle;
                                icon.style.borderWidth = currentBorderWidth;
                            })
                        })

                        circle.on('pm:disable', (e: any) => {
                            const divideNumber = (e.target._mRadius / 1000)
                            const roundNumber = Math.round(divideNumber)

                            const multiplyNumber = (roundNumber * 1000)

                            circle.setRadius(multiplyNumber > 0 ? multiplyNumber : 1000)

                            setGeolocationRadius({
                                radius: multiplyNumber > 0 ? multiplyNumber : 1000
                            })
                        });

                        marker.on('pm:disable', (e: any) => {
                            findGeolocationByLatLon({
                                lat: e.layer._latlng.lat,
                                lon: e.layer._latlng.lng
                            })
                        })

                        map.flyTo(
                            [selectedGeolocation.lat, selectedGeolocation.lon],
                            getZoomLevel(radius),
                            {
                                duration: 1
                            }
                        )
                    }
                } else {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        if (position.coords) {
                            findGeolocationByLatLon({
                                lat: position.coords.latitude,
                                lon: position.coords.longitude
                            })

                            if (circleRef.current) {
                                circleRef.current.remove();
                            }
                            if (markerRef.current) {
                                markerRef.current.remove();
                            }
                        }
                    })
                }
            }
        }, [latLon, map])

        return null
    }

    return <>
        <FilterHeader title="Mapa de Geolocalização" description={<>
            <Typography style={{
                marginBottom: '10px'
            }}>
                Esta opção é uma alternativa aos filtros de localização, podendo filtrar de maneira visual e intuitiva as empresas por uma localização no mapa.
            </Typography>
            <Typography>
                Caso esta opção não seja alterada, serão consideradas todas as localizações na contagem.
            </Typography>
        </>} />

        <Grid item xs={12} py={3}>
            <Divider />
        </Grid>
        <Box p={1}>
            <Grid xs={12}>
                <FormControlLabel control={
                    <Switch
                        checked={selectedFilter?.useGeolocationFilter}
                        onChange={(e) => {
                            setUseGeolocationFilter({
                                useGeolocationFilter: e.target.checked
                            })
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                } label={selectedFilter?.useGeolocationFilter === true ? 'Geolocalização ativada' : 'Geolocalização desativada'} />
            </Grid>
            <form
                onSubmit={((e) => {
                    e.preventDefault()
                    findGeolocation()
                })}
            >
                <Grid container mb={3} display={'flex'} justifyContent={'space-between'}>
                    <Grid item xs={selectedGeolocation ? 7 : 12} display={'flex'} alignItems={'center'} my={1}>
                        <TextField
                            value={searchTerm}
                            margin="dense"
                            label="Digite um CEP, bairro, cidade, estado ou região"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => {
                                setSearchTerm({
                                    searchTerm: e.target.value
                                })
                            }}
                        />
                    </Grid>
                    {
                        selectedGeolocation ? <>
                            <Grid item xs={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Divider orientation="vertical" sx={{ height: '25px' }} />
                            </Grid>
                            <Grid item xs={3} display={'flex'} alignItems={'center'} my={1}>
                                <TextField
                                    value={(radius / 1000)}
                                    margin="dense"
                                    label="Digite um raio (em km)"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => {
                                        const formatNumber = Number(onlyNumbers(e.target.value))

                                        setGeolocationRadius({
                                            radius: (formatNumber * 1000)
                                        })
                                    }}
                                />
                            </Grid>
                        </>
                            : null
                    }
                    <Grid item xs={7} my={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            endIcon={<Search />}
                            sx={{
                                '&:hover': {
                                    backgroundColor: darkenColor(theme.palette.primary.main, 10)
                                }
                            }}
                        >
                            Pesquisar
                        </Button>
                    </Grid>
                    <Grid item xs={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Divider orientation="vertical" sx={{ height: '25px' }} />
                    </Grid>
                    <Grid item xs={3} my={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            endIcon={<MyLocation />}
                            onClick={(e) => {
                                navigator.geolocation.getCurrentPosition(function (position) {
                                    if (position.coords) {
                                        findGeolocationByLatLon({
                                            lat: position.coords.latitude,
                                            lon: position.coords.longitude
                                        })
                                    }
                                })
                            }}
                            sx={{
                                '&:hover': {
                                    backgroundColor: darkenColor(theme.palette.primary.main, 10)
                                }
                            }}
                        >
                            Meu local
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <MapContainer
                center={[latLon ? latLon[0] : -23.59499168284, latLon ? latLon[1] : -46.689440331743]}
                zoom={15}
                minZoom={4}
            >
                <TileLayer
                    attribution=''
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                // attribution=''
                // url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.jpg"
                />
                <ResetCenterView latLon={latLon} />
                {
                    selectedGeolocation ?
                        selectedGeolocation.latLon ?
                            selectedGeolocation.latLon.type !== "Point" ?
                                selectedGeolocation.latLon.type === 'MultiPolygon' ?
                                    selectedGeolocation.latLon.coordinates.map((coordinate: any) => {
                                        return (
                                            <>
                                                <Polygon
                                                    pmIgnore={true}
                                                    pathOptions={{ color: theme.palette.primary.main }}
                                                    positions={coordinate.map((coordinate: any) => {
                                                        return [coordinate[1], coordinate[0]]
                                                    })}
                                                />
                                            </>
                                        )
                                    })
                                    : <>
                                        <Polygon
                                            pmIgnore={true}
                                            pathOptions={{ color: theme.palette.primary.main }}
                                            positions={selectedGeolocation.latLon.coordinates.map((coordinate) => {
                                                return [coordinate[1], coordinate[0]]
                                            })}
                                        />
                                    </>
                                : null
                            : null
                        : null
                }
            </MapContainer>
        </Box>

        <Dialog maxWidth="md" open={open} onClose={() => {
            setGeolocationOpen({
                open: false
            })
        }}>
            <Grid container display={'flex'}>
                <Grid item xs={12}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <DialogTitle
                        color="primary"
                        fontWeight={"bold"}
                        textTransform={"uppercase"}
                        sx={{
                            flexGrow: 1,
                            textAlign: 'center'
                        }}
                    >
                        Localizações encontradas
                    </DialogTitle>
                    <IconButton
                        onClick={() => {
                            setGeolocationOpen({
                                open: false
                            })
                        }}
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Close />
                    </IconButton>
                </Grid>
                <Divider />
            </Grid>
            <List>
                {
                    geolocationsFound ?
                        geolocationsFound.map((geolocation, index) => {
                            return (<>
                                <Grid key={index} container display={'flex'} alignItems={'center'}>
                                    <ListItem
                                        key={index}
                                        button
                                        onClick={() => {
                                            setSelectedGeolocation({
                                                geolocation: geolocation
                                            })
                                            setGeolocationOpen({
                                                open: false
                                            })
                                        }}
                                    >
                                        <Grid item xs={1.5} display={'flex'} justifyContent={'center'}>
                                            {
                                                geolocation.placeRank === 4 ? <FlagCircle />
                                                    : geolocation.placeRank >= 5 && geolocation.placeRank <= 9 ? <SouthAmerica />
                                                        : geolocation.placeRank >= 10 && geolocation.placeRank <= 12 ? <Traffic />
                                                            : geolocation.placeRank >= 13 && geolocation.placeRank <= 16 ? <LocationCity />
                                                                : geolocation.placeRank >= 17 && geolocation.placeRank <= 24 ? <NaturePeople />
                                                                    : geolocation.placeRank >= 26 && geolocation.placeRank <= 27 ? <Signpost />
                                                                        : geolocation.placeRank >= 28 && geolocation.placeRank <= 30 ? <AccountBalance />
                                                                            : <PushPin />

                                            }
                                        </Grid>
                                        <Grid item xs={10}>
                                            <ListItemText
                                                primary={geolocation.name}
                                                secondary={GetGeolocationTypeLabel(geolocation.placeRank)}
                                            />
                                        </Grid>
                                    </ListItem >
                                </Grid>
                                <Divider />
                            </>)
                        })
                        : null
                }
            </List>
        </Dialog >
    </>
}