import { useCallback } from "react"
import { IProjectFilter } from "../../application/models"
import { IProject } from "../../application/models/IProject"
import { IProjectReport } from "../../application/models/IProjectReport"
import { IProjectResume } from "../../application/states/project"
import { useAxiosInstances } from "../axios-instance"

export const useProjectApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()
  
  const findUserProjects = useCallback((data: {
    userId: string
    page?: number
    limit?: number
    active?: boolean
    filters?: {
      name?: string
      createdBy?: string
      startDate?: Date
      endDate?: Date
    }
  }): Promise<{
    projects: IProjectResume[]
    total: number
    page: number
  }> => {
    return axiosAuthInstance.get('/project/user-projects', {
      params: {
        userId: data.userId,
        page: data.page,
        limit: data.limit,
        active: data.active,
        name: data.filters?.name,
        createdBy: data.filters?.createdBy,
        startDate: data.filters?.startDate,
        endDate: data.filters?.endDate
      }
    }).then((response) => {
      const projects = response.data?.result?.projectList
      const page = response.data?.result?.page
      const total = response.data?.result?.total
      if (projects) {
        return {
          projects: projects,
          page: page,
          total: total
        }
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Projetos não encontrados'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])

  const findProjectById = useCallback((data: {
    projectId: string
  }): Promise<IProject> => {
    return axiosAuthInstance.get('/project/' + data.projectId).then((response) => {
      const project = response.data?.result?.project
      if (project) {
        return project
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Projeto não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])

  const updateFilter = useCallback((data: {
    filter: IProjectFilter,
    projectId: string
  }): Promise<{
    projectFilter: IProjectFilter,
    projectReport: IProjectReport
  }> => {
    return axiosAuthInstance.put('/project/update-filter', {
      projectFilter: data.filter,
      projectId: data.projectId
    }).then((response) => {
      const projectFilter = response.data?.result?.projectFilter
      const projectReport = response.data?.result?.projectReport
      if (projectFilter && projectReport) {
        return {
          projectFilter: projectFilter,
          projectReport: projectReport
        }
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Projeto não encontrado'
        }
      }
    })
  }, [axiosAuthInstance])

  const exportProject = useCallback(async (data: {
    filterId?: string,
    list?: string[],
    name: string,
    companyId: string,
    createdBy: string,
    projectId: string,
    total: number
  }): Promise<void> => {
    return axiosAuthInstance.post('/project-export', {
      projectData: {
        filterId: data.filterId ?? undefined,
        name: data.name,
        companyId: data.companyId,
        createdBy: data.createdBy,
        projectId: data.projectId,
        total: data.total,
        list: data.list ?? undefined,
      }
    })
  }, [axiosAuthInstance])

  const createProjectApi = useCallback((data: {
    name: string,
    companyId: string,
    createdBy: string
  }): Promise<IProject> => {
    return axiosAuthInstance.post('/project', {
      projectData: {
        name: data.name,
        companyId: data.companyId,
        createdBy: data.createdBy
      }
    }).then((response) => {
      const project = response.data?.result?.project
      if (project) {
        return project
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Projeto não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])

  const projectIsExportingApi = useCallback((data: {
    projectId: string
  }): Promise<boolean> => {
    return axiosAuthInstance.get('/project/is-exporting', {
      params: {
        projectId: data.projectId
      }
    }).then((response) => {
      const isExporting = response.data?.result?.isExporting
      if (isExporting) {
        return isExporting
      } else {
        return false
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])

  const updateProjectStatusApi = useCallback((data: {
    projectId: string,
    status: boolean
  }): Promise<void> => {
    return axiosAuthInstance.put('/project/status', {
      projectId: data.projectId,
      status: data.status
    }).then((response) => {
      const project = response.data?.result?.project
      if (project) {
        return project
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Projeto não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])


  const updateProjectNameApi = useCallback((data: {
    projectId: string,
    name: string
  }): Promise<void> => {
    return axiosAuthInstance.put('/project/name', {
      projectId: data.projectId,
      name: data.name
    }).then((response) => {
      const project = response.data?.result?.project
      if (project) {
        return project
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Projeto não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])

  const cloneProjectApi = useCallback((data: {
    projectId: string,
    createdBy: string,
    name: string
  }): Promise<IProject> => {
    return axiosAuthInstance.post('/project/clone', {
      projectId: data.projectId,
      createdBy: data.createdBy,
      name: data.name
    }).then((response) => {
      const project = response.data?.result?.project
      if (project) {
        return project
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Projeto não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])






  return {
    projectIsExportingApi,
    findUserProjects,
    findProjectById,
    updateFilter,
    exportProject,
    createProjectApi,
    updateProjectStatusApi,
    cloneProjectApi,
    updateProjectNameApi
  }
}