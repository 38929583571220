import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICanceledProjectExport, IProjectExport } from '../../models';

interface IProjectExportState {
  page: number;
  loading: boolean;
  pageSize: number;
  total: number;
  companies: {
    companyId: string
    cnpj: string
    razaoSocial: string
  }[]
  users: {
    userId: string
    email: string
    name: string
  }[]
  exportList: Array<{
    projectExport: IProjectExport,
    fileExists: boolean
    canceledProjectExport?: ICanceledProjectExport
  }>
  tableFilters: {
    name?: string;
    companyId?: string;
    createdBy?: string;
    startDate?: Date;
    endDate?: Date;
    type?: string;
  }
}

const initialState: IProjectExportState = {
  exportList: [],
  page: 1,
  pageSize: 10,
  total: 0,
  loading: false,
  companies: [],
  users: [],
  tableFilters: {
    name: undefined,
    companyId: undefined,
    createdBy: undefined,
    startDate: undefined,
    endDate: undefined,
    type: undefined
  }
};

const projectExportSlice = createSlice({
  name: 'projectExport',
  initialState,
  reducers: {
    setExportListState: (state, action: PayloadAction<{
      exportList: {
        projectExport: IProjectExport
        fileExists: boolean
        canceledProjectExport?: ICanceledProjectExport
      }[],
      page: number,
      total: number,
    }>) => {
      state.exportList = action.payload.exportList;
      state.page = action.payload.page;
      state.total = action.payload.total;
    },
    setProjectExportPageState: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setProjectExportListLoadingState: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetTableFiltersInitialState(state) {
      state.tableFilters = initialState.tableFilters
    },
    setTableFilterCompanyIdState(state, action: PayloadAction<{
      companyId: string
    }>) {
      state.tableFilters.companyId = action.payload.companyId
    },
    setTableFilterCreatedByState(state, action: PayloadAction<{
      createdBy: string
    }>) {
      state.tableFilters.createdBy = action.payload.createdBy
    },
    setTableFilterEndDateState(state, action: PayloadAction<{
      endDate?: Date
    }>) {
      state.tableFilters.endDate = action.payload.endDate
    },
    setTableFilterNameState(state, action: PayloadAction<{
      name: string
    }>) {
      state.tableFilters.name = action.payload.name
    },
    setTableFilterStartDateState(state, action: PayloadAction<{
      startDate?: Date
    }>) {
      state.tableFilters.startDate = action.payload.startDate
    },
    setTableFilterTypeState(state, action: PayloadAction<{
      type: string
    }>) {
      state.tableFilters.type = action.payload.type
    },
    setCompanyListState(state, action: PayloadAction<{
      companies: {
        companyId: string
        cnpj: string
        razaoSocial: string
      }[]
    }>) {
      state.companies = action.payload.companies
    },
    setUserListState(state, action: PayloadAction<{
      users: {
        userId: string
        email: string
        name: string
      }[]
    }>) {
      state.users = action.payload.users
    }
  },
});

export const {
  setExportListState,
  setProjectExportPageState,
  setProjectExportListLoadingState,
  resetTableFiltersInitialState,
  setTableFilterCompanyIdState,
  setTableFilterCreatedByState,
  setTableFilterEndDateState,
  setTableFilterStartDateState,
  setTableFilterTypeState,
  setTableFilterNameState,
  setCompanyListState,
  setUserListState
} = projectExportSlice.actions;

export const projectExportReducer = projectExportSlice.reducer;