import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useAppSelector } from '../../../../../application/states/hooks';
import { useEffect, useState, useRef } from 'react';
import { useProjectExportService } from '../../../../../use-cases/project-export';
import { debounce, ListItem, ListItemText } from '@mui/material';

export const UsersVirtualizedList = () => {
    // Service
    const { getVirtualizedUserList, setTableFilterCreatedBy } = useProjectExportService();
    // State
    const { users } = useAppSelector((state) => state.projectExport);
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [userOptions, setUserOptions] = useState<{
        userId: string
        email: string
        name: string
    }[]>([])
    const [selectedUser, setSelectedUser] = useState<{
        userId: string
        email: string
        name: string
    } | null>(null)

    const isFirstRender = useRef(true)
    const listRef = useRef<HTMLElement | null>(null)

    const fetchOptions = async (searchTerm: string, page: number) => {
        if (isLoading) return

        setIsLoading(true)
        await getVirtualizedUserList({
            searchTerm: searchTerm,
            page: page,
        })
        setIsLoading(false)
    }

    useEffect(() => {
        if (!isFirstRender.current) {
            fetchOptions(searchTerm, page)
        } else {
            isFirstRender.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchTerm])

    useEffect(() => {
        setUserOptions([])
        setPage(1)
        setHasMore(true)
    }, [searchTerm])

    useEffect(() => {
        if (users && users.length > 0) {
            if (page === 1) {
                setUserOptions([...users])
            } else {
                let newUserList = [...userOptions]

                users.forEach((user) => {
                    if (!newUserList.find((item) => item.userId === user.userId)) {
                        newUserList.push(user)
                    }
                })

                setUserOptions([...newUserList])
            }
            setHasMore(users.length > 49)
        } else {
            setHasMore(false)
        }
    }, [page, userOptions, users])

    const handleScroll = debounce((event: React.SyntheticEvent) => {
        const target = event.target as HTMLElement
        const scrollThreshold = 5
        const scrollPosition = target.scrollTop + target.clientHeight
        const scrollHeight = target.scrollHeight

        const remainingItems = scrollHeight - scrollPosition
        const itemHeight = target.scrollHeight / userOptions.length
        const remainingVisibleItems = Math.floor(remainingItems / itemHeight)

        if (remainingVisibleItems <= scrollThreshold && hasMore && !isLoading) {
            setPage((prevPage) => prevPage + 1)
        }
    }, 1000)

    return (
        <Autocomplete
            noOptionsText={users.length === 0 && !hasMore ? "Não há usuários disponíveis" : "Buscando usuários..."}
            options={userOptions.length > 0 ? userOptions.sort((a, b) => -b.name[0].localeCompare(a.name[0])) : []}
            groupBy={(option) => option.name[0]}
            getOptionLabel={(option) => option.name.toUpperCase() || ''}
            getOptionKey={(option) => option.userId}
            isOptionEqualToValue={(option, value) => option.userId === value.userId}
            value={selectedUser}
            renderOption={(props, option) => {
                const { email, name, userId } = option

                return (
                    <ListItem
                        {...props}
                        key={userId}
                    >
                        <ListItemText primary={name.toUpperCase()} secondary={`E-mail: ${email}`} />
                    </ListItem>
                )
            }}
            ListboxProps={{
                onScroll: handleScroll,
                ref: listRef
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Selecione o usuário"
                    onChange={debounce((e) => setSearchTerm(e.target.value), 1000)}
                />
            )}
            onChange={(event, newValue) => {
                if (newValue) {
                    setTableFilterCreatedBy({
                        createdBy: newValue.userId
                    })
                    setSelectedUser(newValue)
                } else {
                    setSearchTerm('')
                    setSelectedUser(null)
                    setTableFilterCreatedBy({
                        createdBy: ''
                    })
                }
            }}
        />
    )
}
