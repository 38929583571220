import { format } from "date-fns"
import { useCallback } from "react"
import { IActiveDebt, IBusinessSize, IContact, ICreditScoreDescription, IEconomicGroup, IGeolocation, ILocation, IRegimeTributario, ISector, ISegmento, IStatus, SpecialStatus } from "../../application/models"
import { IEconomicActivity } from "../../application/models/IEconomicActivity"
import { ICountReport } from "../../application/models/IProjectReport"
import { setMatchCodeListState } from "../../application/states/data-enrichment"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import {
  setProjectListState,
  setSelectedProjectState,
  setProjectActiveSectionState,
  setActiveFilterState,
  addLocationFilterState,
  removeLocationFilterState,
  addSectorFilterState,
  removeSectorFilterState,
  addSegmentoFilterState,
  removeSegmentoFilterState,
  addEconomicActivityFilterState,
  removeEconomicActivityFilterState,
  addIncludeBusinessSizeFilterState,
  removeIncludeBusinessSizeFilterState,
  setMaxRevenueFilterState,
  setMinRevenueFilterState,
  setMaxEmployeesFilterState,
  setMinEmployeesFilterState,
  setMaxBusinessAssociateFilterState,
  setMinBusinessAssociateFilterState,
  addStatusFilterState,
  removeStatusFilterState,
  addEconomicGroupFilterState,
  removeEconomicGroupFilterState,
  setMaxCreatedAtFilterState,
  setMinCreatedAtFilterState,
  addBusinessContactFilterState,
  removeBusinessContactFilterState,
  removePartnerContactFilterState,
  addPartnetContactFilterState,
  addSpecialStatusFilterState,
  removeSpecialStatusFilterState,
  setActionState,
  ActionTypes,
  setProjectReportState,
  setExportDialogOpen,
  setExportDialogNameState,
  setExportDialogTotalState,
  setActiveDataSectionState,
  setCountReportModalActionState,
  setCountReportModalOpenState,
  selectCountReportState,
  setCountReportListState,
  addPurgeLocationFilterState,
  removePurgeLocationFilterState,
  validateCreateProject,
  setProjectFormErrorsState,
  resetProjectFormErrorsState,
  setProjectNameState,
  setCreateProjectFormOpenState,
  validateCountReport,
  resetCountReportFieldErrorsState,
  setCountReportFieldErrorsState,
  setCreateProjectFormActionState,
  addBlackListFilterState,
  removeBlackListFilterState,
  setJustPrimaryActivityFilterState,
  addCepListFilterState,
  removeCepListFilterState,
  addPurgeCepListFilterState,
  removePurgeCepListFilterState,
  setFilterByLatAndLngCepState,
  setFilterByLatAndLngRadiusState,
  setFilterByLatAndLngState,
  addTermFilterState,
  removeTermFilterState,
  addNotIncludeTermFilterState,
  removeNotIncludeTermFilterState,
  addRegimeTributarioFilterState,
  removeRegimeTributarioFilterState,
  setProjectPageState,
  setProjectActiveFilterState,
  setProjectPageSizeState,
  setMaxShareCapitalFilterState,
  setMinShareCapitalFilterState,
  addActiveDebtsFilterState,
  removeActiveDebtsFilterState,
  setIncludeDebtsFilterState,
  addCreditScoreDescriptionFilterState,
  removeCreditScoreDescriptionFilterState,
  setGeolocationFilterState,
  setGeolocationFilterRadiusState,
  setUseGeolocationFilterState,
  resetTableFiltersInitialState,
  setTableFilterNameState,
  setTableFilterCreatedByState,
  setTableFilterStartDateState,
  setTableFilterEndDateState,
  setAddNaturezaJuridicaFilterState,
  setRemoveNaturezaJuridicaFilterState,
  addNotIncludeBusinessSizeFilterState,
  removeNotIncludeBusinessSizeFilterState
} from "../../application/states/project"
import { executeAlert } from "../../application/states/toast-alert"
import { useCountReportApi } from "../../infra/count-report/useCountReportApi"
import { useProjectApi } from "../../infra/project-api"
import { useLoadingService } from "../loading"
import { useNavigationService } from "../navigation"
import Papa from 'papaparse';
import { countReportFieldToLabel } from "../../presentation/assets/helper-assets/CountReportFields"
import { xlsxGenerator } from "../../utils/convert-to-xlsx/xslxGenerator"
import { getSleep } from "../../utils"
import { setGeolocationRadiusState, setSelectedGeolocationState } from "../../application/states/geolocation"
import { useOpenStreetMapGeolocationApi } from "../../infra"
import { INaturezaJuridica } from "../../application/models/INaturezaJuridica"

function convertToCSV(arr: any[]) {
  const csv = Papa.unparse(arr);
  return csv;
}

export const useProjectService = () => {
  // Dispatch
  const dispatch = useAppDispatch()
  // States
  const user = useAppSelector((state) => state.user)
  const selectedFilter = useAppSelector((state) => state.project.selectedFilter)
  const selectedProjectId = useAppSelector((state) => state.project.selectedProject?.id)
  const exportDialog = useAppSelector((state) => state.project.exportDialog)
  const dataEnrichmentState = useAppSelector((state) => state.dataEnrichment)
  const selectedCountReport = useAppSelector((state) => state.project.selectedCountReport)
  const projectName = useAppSelector((state) => state.project.createProjectForm.name)
  const selectedProject = useAppSelector((state) => state.project.selectedProject)
  const { page, active, pageSize, tableFilters } = useAppSelector((state) => state.project)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
  // Infra - API
  const { findUserProjects, findProjectById, updateFilter, exportProject: exportProjectApi, createProjectApi, projectIsExportingApi, updateProjectStatusApi, cloneProjectApi, updateProjectNameApi } = useProjectApi()
  const { updateCountReportApi, createCountReportApi, findCountReportByProjectIdApi, deleteCountReportApi } = useCountReportApi()
  const { findGeolocationByLatLonApi } = useOpenStreetMapGeolocationApi()
  // Services
  const { navigateTo } = useNavigationService()
  const { startLoading, stopLoading } = useLoadingService()

  const projectIsExporting = useCallback((data: {
    projectId: string
  }) => {
    return projectIsExportingApi({
      projectId: data.projectId
    })
  }, [projectIsExportingApi])

  const setProjectList = useCallback(async () => {
    if (user.id === '' || !user.id) {
      return
    }

    startLoading()
    findUserProjects({
      userId: user.id,
      limit: pageSize,
      page: page,
      active: active,
      filters: tableFilters
    }).then((findProjects) => {
      dispatch(setProjectListState({
        projectList: findProjects.projects,
        page: findProjects.page,
        total: findProjects.total,
        pageSize: pageSize
      }))
    }).catch(() => {
      dispatch(executeAlert({
        type: 'error',
        message: 'Erro ao buscar projetos'
      }))
    }).finally(() => stopLoading())
  }, [user.id, startLoading, findUserProjects, pageSize, page, active, tableFilters, dispatch, stopLoading])

  const setActiveSection = useCallback((data: {
    action: 'filter' | 'data'
  }) => {
    dispatch(setProjectActiveSectionState({
      activeSection: data.action
    }))
  }, [dispatch])

  const setActiveFilter = useCallback((data: {
    activeFilter: number
  }) => {
    dispatch(setActiveFilterState({
      activeFilter: data.activeFilter
    }))
  }, [dispatch])

  const addLocationFilter = useCallback((data: {
    location: ILocation,
  }) => {
    dispatch(addLocationFilterState({
      location: data.location
    }))
  }, [dispatch])

  const removeLocationFilter = useCallback((data: {
    location: ILocation,
  }) => {
    dispatch(removeLocationFilterState({
      location: data.location
    }))
  }, [dispatch])

  const addPurgeLocationFilter = useCallback((data: {
    location: ILocation,
  }) => {
    dispatch(addPurgeLocationFilterState({
      location: data.location
    }))
  }, [dispatch])

  const removePurgeLocationFilter = useCallback((data: {
    location: ILocation,
  }) => {
    dispatch(removePurgeLocationFilterState({
      location: data.location
    }))
  }, [dispatch])


  const addSectorFilter = useCallback((data: {
    sector: ISector
  }) => {
    dispatch(addSectorFilterState({
      sector: data.sector
    }))
  }, [dispatch])

  const removeSectorFilter = useCallback((data: {
    sector: ISector
  }) => {
    dispatch(removeSectorFilterState({
      sector: data.sector
    }))
  }, [dispatch])

  const addSegmentoFilter = useCallback((data: {
    segmento: ISegmento
  }) => {
    dispatch(addSegmentoFilterState({
      segmento: data.segmento
    }))
  }, [dispatch])

  const removeSegmentoFilter = useCallback((data: {
    segmento: ISegmento
  }) => {
    dispatch(removeSegmentoFilterState({
      segmento: data.segmento
    }))
  }, [dispatch])

  const addEconomicActivityFilter = useCallback((data: {
    economicActivity: IEconomicActivity
  }) => {

    dispatch(addEconomicActivityFilterState({
      economicActivity: data.economicActivity
    }))

  }, [dispatch])

  const removeEconomicAcivityFilter = useCallback((data: {
    economicActivity: IEconomicActivity
  }) => {
    dispatch(removeEconomicActivityFilterState({
      economicActivity: data.economicActivity
    }))
  }, [dispatch])

  const addIncludeBusinessSizeFilter = useCallback((data: {
    businessSize: IBusinessSize
  }) => {
    dispatch(addIncludeBusinessSizeFilterState({
      businessSize: data.businessSize
    }))
  }, [dispatch])

  const removeIncludeBusinessSizeFilter = useCallback((data: {
    businessSize: IBusinessSize
  }) => {
    dispatch(removeIncludeBusinessSizeFilterState({
      businessSize: data.businessSize
    }))
  }, [dispatch])

  const addNotIncludeBusinessSizeFilter = useCallback((data: {
    businessSize: IBusinessSize
  }) => {
    dispatch(addNotIncludeBusinessSizeFilterState({
      businessSize: data.businessSize
    }))
  }, [dispatch])

  const removeNotIncludeBusinessSizeFilter = useCallback((data: {
    businessSize: IBusinessSize
  }) => {
    dispatch(removeNotIncludeBusinessSizeFilterState({
      businessSize: data.businessSize
    }))
  }, [dispatch])

  const setRevenueMaxFilter = useCallback((data: {
    revenueMax: number
  }) => {
    dispatch(setMaxRevenueFilterState({
      maxRevenue: data.revenueMax
    }))
  }, [dispatch])

  const setRevenueMinFilter = useCallback((data: {
    revenueMin: number
  }) => {
    dispatch(setMinRevenueFilterState({
      minRevenue: data.revenueMin
    }))
  }, [dispatch])

  const setMaxEmployeeFilter = useCallback((data: {
    maxEmployee: number
  }) => {
    dispatch(setMaxEmployeesFilterState({
      maxEmployees: data.maxEmployee
    }))
  }, [dispatch])

  const setMinEmployeeFilter = useCallback((data: {
    minEmployee: number
  }) => {
    dispatch(setMinEmployeesFilterState({
      minEmployees: data.minEmployee
    }))
  }, [dispatch])

  const setMaxBusinessAssociateFilter = useCallback((data: {
    maxBusinessAssociate: number
  }) => {
    dispatch(setMaxBusinessAssociateFilterState({
      maxBusinessAssociate: data.maxBusinessAssociate
    }))
  }, [dispatch])

  const setMinBusinessAssociateFilter = useCallback((data: {
    minBusinessAssociate: number
  }) => {
    dispatch(setMinBusinessAssociateFilterState({
      minBusinessAssociate: data.minBusinessAssociate
    }))
  }, [dispatch])

  const addStatusFilter = useCallback((data: {
    status: IStatus
  }) => {
    dispatch(addStatusFilterState({
      status: data.status
    }))
  }, [dispatch])

  const removeStatusFilter = useCallback((data: {
    status: IStatus
  }) => {
    dispatch(removeStatusFilterState({
      status: data.status
    }))
  }, [dispatch])

  const addRegimeTributarioFilter = useCallback((data: {
    regimeTributario: IRegimeTributario
  }) => {
    dispatch(addRegimeTributarioFilterState({
      regimeTributario: data.regimeTributario
    }))
  }, [dispatch])

  const removeRegimeTributarioFilter = useCallback((data: {
    regimeTributario: IRegimeTributario
  }) => {
    dispatch(removeRegimeTributarioFilterState({
      regimeTributario: data.regimeTributario
    }))
  }, [dispatch])

  const addEconomicGroupFilter = useCallback((data: {
    economicGroup: IEconomicGroup
  }) => {
    dispatch(addEconomicGroupFilterState({
      economicGroup: data.economicGroup
    }))
  }, [dispatch])

  const removeEconomicGroupFilter = useCallback((data: {
    economicGroup: IEconomicGroup
  }) => {
    dispatch(removeEconomicGroupFilterState({
      economicGroup: data.economicGroup
    }))
  }, [dispatch])

  const setMaxCreatedAtFilter = useCallback((data: {
    maxCreatedAt?: Date
  }) => {
    dispatch(setMaxCreatedAtFilterState({
      maxCreatedAt: data.maxCreatedAt
    }))
  }, [dispatch])

  const setMinCreatedAtFilter = useCallback((data: {
    minCreatedAt?: Date
  }) => {
    dispatch(setMinCreatedAtFilterState({
      minCreatedAt: data.minCreatedAt
    }))
  }, [dispatch])

  const addBusinessContactFilter = useCallback((data: {
    businessContact: IContact
  }) => {
    dispatch(addBusinessContactFilterState({
      businessContact: data.businessContact
    }))
  }, [dispatch])

  const removeBusinessContactFilter = useCallback((data: {
    businessContact: IContact
  }) => {
    dispatch(removeBusinessContactFilterState({
      businessContact: data.businessContact
    }))
  }, [dispatch])

  const addPartnetContactFilter = useCallback((data: {
    partnerContact: IContact
  }) => {
    dispatch(addPartnetContactFilterState({
      partnerContact: data.partnerContact
    }))
  }, [dispatch])

  const removePartnerContactFilter = useCallback((data: {
    partnerContact: IContact
  }) => {
    dispatch(removePartnerContactFilterState({
      partnerContact: data.partnerContact
    }))
  }, [dispatch])

  const addSpecialStatusFilter = useCallback((data: {
    specialStatus: SpecialStatus
  }) => {
    dispatch(addSpecialStatusFilterState({
      specialStatus: data.specialStatus
    }))
  }, [dispatch])

  const removeSpecialStatusFilter = useCallback((data: {
    specialStatus: SpecialStatus
  }) => {
    dispatch(removeSpecialStatusFilterState({
      specialStatus: data.specialStatus
    }))
  }, [dispatch])

  const setAction = useCallback((data: {
    action: ActionTypes
  }) => {
    dispatch(setActionState({
      actionType: data.action
    }))
  }, [dispatch])

  const openExportDialog = useCallback(() => {
    dispatch(setExportDialogOpen({
      exportDialogOpen: true
    }))
  }, [dispatch])

  const closeExportDialog = useCallback(() => {
    dispatch(setExportDialogOpen({
      exportDialogOpen: false
    }))
  }, [dispatch])

  const setExportDialogName = useCallback((data: {
    name: string
  }) => {
    dispatch(setExportDialogNameState({
      exportDialogName: data.name
    }))
  }, [dispatch])

  const setExportDialogTotal = useCallback((data: {
    total: number
  }) => {
    dispatch(setExportDialogTotalState({
      exportDialogTotal: data.total
    }))
  }, [dispatch])

  const openProject = useCallback((data: {
    projectId: string
  }) => {
    startLoading()
    findProjectById(data).then((project) => {
      dispatch(setSelectedProjectState({
        selectedProject: project 
      }))
      dispatch(setProjectReportState({
        projectReport: undefined
      }))

      if (project.filters[0]) {
        if (project.filters[0].geolocation) {
          dispatch(setGeolocationRadiusState({
            radius: Number(project.filters[0].geolocation.radius * 1000)
          }))

          findGeolocationByLatLonApi({
            lat: project.filters[0].geolocation.lat,
            lon: project.filters[0].geolocation.lon
          }).then((response) => {
            if (response) {
              const { geolocation } = response

              const newGeolocation: IGeolocation = {
                address: {
                  city: geolocation.address.city,
                  country: geolocation.address.country,
                  county: geolocation.address.county,
                  municipality: geolocation.address.municipality,
                  postcode: geolocation.address.postcode,
                  quarter: geolocation.address.quarter,
                  railway: geolocation.address.railway,
                  region: geolocation.address.region,
                  road: geolocation.address.road,
                  state: geolocation.address.state,
                  stateDistrict: geolocation.address.state_district,
                  suburb: geolocation.address.suburb,
                },
                latLon: {
                  coordinates: [
                    [Number(geolocation.lat), Number(geolocation.lon)]
                  ],
                  type: 'Point'
                },
                lat: Number(geolocation.lat),
                lon: Number(geolocation.lon),
                name: geolocation.display_name,
                placeRank: 28,
                type: geolocation.type
              }

              dispatch(setSelectedGeolocationState({
                geolocation: newGeolocation
              }))
            }
          }).catch((error) => {
            dispatch(executeAlert({
              message: 'Não foi possível recuperar a geolocalização deste projeto',
              type: 'error'
            }))
          })
        }
      }

      return project
    }).then((project) => {

      stopLoading()
      setExportDialogName({
        name: `${project.name} - ${format(new Date(), 'dd/MM/yyyy')}`
      })
      dispatch(executeAlert({
        message: 'Projeto carregado com sucesso',
        type: 'success'
      }))

      navigateTo({
        route: `/workspace/projects/details`
      })
    }).catch((error) => {
      stopLoading()
      dispatch(executeAlert({
        message: error.message,
        type: 'error'
      }))
    })
  }, [startLoading, findProjectById, dispatch, findGeolocationByLatLonApi, stopLoading, setExportDialogName, navigateTo])

  const setActiveDataSection = useCallback((data: {
    activeDataSection: number
  }) => {
    dispatch(setActiveDataSectionState({
      activeDataSection: data.activeDataSection
    }))
  }, [dispatch])

  const closeProject = useCallback(() => {
    setActiveDataSection({
      activeDataSection: 0
    })

    setActiveFilter({
      activeFilter: 0
    })

    setActiveSection({
      action: 'filter'
    })



    navigateTo({
      route: '/workspace/projects'
    })
  }, [
    setActiveDataSection,
    setActiveFilter,
    navigateTo,
    setActiveSection

  ])

  const exportProject = useCallback((data: {
    type: 'enrichment' | 'project'
  }) => {
    let obj: any = {}

    if (data.type === 'enrichment') {
      if (dataEnrichmentState.matchCodeList?.length === 0) {
        dispatch(executeAlert({
          message: 'Nenhum match code encontrado',
          type: 'error'
        }))
        return
      }
      obj.name = dataEnrichmentState.fileData.name
      obj.total = dataEnrichmentState.matchCodeList?.length ?? 0
      obj.list = dataEnrichmentState.matchCodeList
    }
    if (data.type === 'project') {
      obj.name = exportDialog.name ?? ''
      obj.projectId = selectedProjectId ?? ''

      if (exportDialog.total === 0) {
        dispatch(executeAlert({
          message: "O campo 'total' não pode ser igual a 0",
          type: 'error'
        }))
        return
      }
      obj.total = exportDialog.total

      obj.filterId = selectedFilter?.id ?? undefined
    }
    obj.companyId = user.companyId
    obj.createdBy = user.id



    exportProjectApi({
      ...obj
    }).then(async () => {
      dispatch(setMatchCodeListState({
        matchCodeList: undefined
      }))
      dispatch(executeAlert({
        message: 'Projeto exportado com sucesso',
        type: 'success'
      }))
      closeExportDialog()
      closeProject()
      await getSleep(3000)
      navigateTo({
        route: '/workspace/project-exports'
      })
    }).catch((err) => {
      dispatch(executeAlert({
        message: err?.response?.data?.result?.message ?? 'Erro ao exportar aa',
        type: 'error'
      }))
    })
  }, [user, dispatch, selectedFilter, selectedProjectId, exportProjectApi, exportDialog.name, exportDialog.total, closeExportDialog, closeProject, navigateTo, dataEnrichmentState.matchCodeList, dataEnrichmentState.fileData.name])

  const setCountReportList = useCallback(() => {
    startLoading()
    findCountReportByProjectIdApi({ projectId: selectedProjectId ?? '' }).then((countReports) => {
      dispatch(setCountReportListState({
        countReportList: countReports
      }))
      stopLoading()
    })
  }, [selectedProjectId, findCountReportByProjectIdApi, dispatch, startLoading, stopLoading])

  const setCountReportModalAction = useCallback((data: {
    countReportModalAction: 'create' | 'edit'
  }) => {
    dispatch(setCountReportModalActionState({
      countReportModalAction: data.countReportModalAction
    }))
  }, [dispatch])

  const selectCountReport = useCallback((data: {
    countReport: ICountReport
  }) => {
    dispatch(selectCountReportState({
      selectedCountReport: data.countReport
    }))
  }, [dispatch])

  const openCountReportModal = useCallback(() => {
    dispatch(setCountReportModalOpenState({
      countReportModalOpen: true
    }))
  }, [dispatch])

  const closeCountReportModal = useCallback(() => {
    dispatch(setCountReportModalOpenState({
      countReportModalOpen: false
    }))
  }, [dispatch])

  const createCountReport = useCallback(() => {
    dispatch(resetCountReportFieldErrorsState())
    const errors = validateCountReport({
      name: selectedCountReport.name,
    })
    if (errors.length > 0) {
      dispatch(setCountReportFieldErrorsState({
        errors: errors
      }))
      dispatch(executeAlert({
        message: errors[0].fieldError.message,
        type: 'error'
      })
      )

    } else {
      startLoading()
      createCountReportApi({
        countReport: {
          projectId: selectedProjectId ?? '',
          columns: selectedCountReport.columns,
          companyId: user.companyId,
          name: selectedCountReport.name,
          createdBy: user.id,
        }
      }).then((response) => {
        setCountReportModalAction({ countReportModalAction: 'edit' })
        selectCountReport({ countReport: response })
        setCountReportList()
        stopLoading()
        closeCountReportModal()
      }).catch((error) => {
        stopLoading()
        dispatch(executeAlert({
          message: error?.response?.data?.result?.message ?? 'Erro ao criar relatório',
          type: 'error'
        }))
      })
    }

  }, [createCountReportApi, selectedCountReport, selectCountReport, setCountReportList, setCountReportModalAction, startLoading, stopLoading, user.companyId, user.id, selectedProjectId, dispatch, closeCountReportModal])

  const updateCountReport = useCallback(() => {
    dispatch(resetCountReportFieldErrorsState())
    const errors = validateCountReport({
      name: selectedCountReport.name,
    })
    if (errors.length > 0) {
      dispatch(setCountReportFieldErrorsState({
        errors: errors
      }))
      dispatch(executeAlert({
        message: errors[0].fieldError.message,
        type: 'error'
      })
      )
    } else {
      startLoading()
      updateCountReportApi({
        countReport: {
          columns: selectedCountReport.columns,
          id: selectedCountReport.id,
          name: selectedCountReport.name,
        }
      }).then(() => {
        setCountReportList()
        stopLoading()
        closeCountReportModal()
      }).catch((error) => {
        stopLoading()
        dispatch(executeAlert({
          message: error?.response?.data?.result?.message ?? 'Erro ao atualizar relatório',
          type: 'error'
        }))
      })
    }

  }, [selectedCountReport, updateCountReportApi, setCountReportList, startLoading, stopLoading, dispatch, closeCountReportModal])

  const deleteCountReport = useCallback((data: {
    countReportId: string
  }) => {
    startLoading()
    deleteCountReportApi({
      countReportId: data.countReportId
    }).then(() => {
      setCountReportList()
      stopLoading()
    })
  }, [deleteCountReportApi, setCountReportList, startLoading, stopLoading,])

  const updateProjectFilter = useCallback(() => {
    if (selectedFilter) {
      dispatch(setProjectReportState({
        projectReport: undefined
      }))

      startLoading()
      updateFilter({
        filter: selectedFilter,
        projectId: selectedProjectId ?? ''
      }).then((response) => {
        dispatch(setProjectReportState({
          projectReport: {
            ...response.projectReport,
            samples: response.projectReport.samples.map((item: any) => {
              return {
                id: item.pessoajuridicaid,
                cnpj: item.cnpj,
                razaoSocial: item.razaosocial,
                telefone: item?.telefones?.[0] ?? '',
                endereco: item.enderecocompleto,
                nomeFantasia: item.nomefantasia,
              }
            })
          }
        }))
        setCountReportList()
        stopLoading()
        setAction({
          action: 'export'
        })
      }).catch((error) => {
        stopLoading()
        dispatch(executeAlert({
          message: 'Erro ao atualizar filtro. Dentre as possíveis causas estão:\n Excesso de contagens; \n Base pouco segmentada',
          type: 'error'
        }))
      })
    }

  }, [selectedFilter, dispatch, startLoading, updateFilter, selectedProjectId, setCountReportList, stopLoading, setAction])

  const downloadCountReport = useCallback((
    data: {
      type: 'csv' | 'xlsx'
    }) => {
    if (data.type === 'csv') {
      let csv = convertToCSV(selectedCountReport.rows.map((row) => {
        let orderedRow: any = {}
        selectedCountReport.columns.forEach((column: any, index: number) => {
          orderedRow[column] = row[column]
        })
        if (row.Outros) {
          if (row['count'] > 0) {
            orderedRow['Total'] = "CNAE's secundários " + row['count']
          }
        } else {
          orderedRow['Total'] = row['count']

        }

        return orderedRow
      }))

      let csvLines = csv.split('\n')
      /* Change CSV Header */
      csvLines[0] = selectedCountReport.columns.map((column: any) => {
        return countReportFieldToLabel(column)
      }).join(',') + ',Total'

      csv = csvLines.join('\n')

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', selectedCountReport.name + '.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {

      let primaryColor = '723989'
      let contrastColor = 'FFFFFFFF'
      let fgColor = 'e8e4d6'

      if (resaleConfig) {
        if (resaleConfig.id !== '') {
          if (resaleConfig.theme.colors.primary.main !== '') {
            primaryColor = resaleConfig.theme.colors.primary.main
          }
          if (resaleConfig.theme.colors.primary.contrastColor !== '') {
            contrastColor = resaleConfig.theme.colors.primary.contrastColor
          }
        }
      }

      const transformedData = selectedCountReport.rows.map((row) => {
        let orderedRow: any = {}

        for (let i = 0; i < selectedCountReport.columns.length; i++) {
          const column = selectedCountReport.columns[i]
          orderedRow[countReportFieldToLabel(column)] = row[column]
        }

        if (row.Outros) {
          if (row['count'] > 0) {
            orderedRow['Total'] = "CNAE's secundários " + row['count']
          }
        } else {
          orderedRow['Total'] = row['count']
        }

        return Object.values(orderedRow)
      })

      const lastRow = transformedData[transformedData.length - 1]
      if (lastRow && lastRow.length === selectedCountReport.columns.length + 1) {
        xlsxGenerator(
          transformedData,
          [...selectedCountReport.columns.map((col) => countReportFieldToLabel(col)), 'Total'],
          selectedCountReport.name,
          primaryColor,
          contrastColor,
          fgColor
        )
      } else {
        // Remover a última linha do array
        transformedData.pop()

        // Passar os dados transformados para a função xlsxGenerator
        xlsxGenerator(
          transformedData,
          [...selectedCountReport.columns.map((col) => countReportFieldToLabel(col)), 'Total'],
          selectedCountReport.name,
          primaryColor,
          contrastColor,
          fgColor
        )
      }
    }
  }, [resaleConfig, selectedCountReport.columns, selectedCountReport.name, selectedCountReport.rows])

  const openCreateProjectForm = useCallback((data: {
    action: 'CREATE' | 'CLONE' | 'EDIT',
    projectToCLone?: string
  }) => {
    dispatch(setCreateProjectFormActionState({
      action: data.action,
      projectToClone: data.projectToCLone
    }))
    dispatch(setCreateProjectFormOpenState({
      open: true
    }))
  }, [dispatch])

  const closeCreateProjectForm = useCallback(() => {
    dispatch(setCreateProjectFormOpenState({
      open: false
    }))
  }, [dispatch])

  const createProject = useCallback(() => {
    dispatch(resetProjectFormErrorsState())
    const errors = validateCreateProject({
      name: projectName
    })
    if (errors.length > 0) {
      dispatch(setProjectFormErrorsState({
        errors: errors
      }))
      dispatch(executeAlert({
        message: errors[0].fieldError.message,
        type: 'error'
      }))
    } else {
      startLoading()
      createProjectApi({
        companyId: user.companyId,
        name: projectName,
        createdBy: user.id
      }).then((response) => {
        setProjectList()
        stopLoading()
        closeCreateProjectForm()
        dispatch(setProjectNameState({
          name: ''
        }))
        openProject({
          projectId: response.id
        })
      })
    }

  }, [dispatch, projectName, startLoading, createProjectApi, user.companyId, user.id, setProjectList, stopLoading, closeCreateProjectForm, openProject])

  const getCurrentProjectReport = useCallback((data: {
    projectId: string
  }) => {
    return findProjectById({
      projectId: data.projectId
    }).then((response) => {
      return response.projectReport
    })
  }, [findProjectById])

  const setProjectName = useCallback((data: {
    name: string
  }) => {
    dispatch(setProjectNameState({
      name: data.name
    }))
  }, [dispatch])

  const updateProjectName = useCallback((data: {
    projectId: string,
    name: string
  }) => {
    startLoading()
    updateProjectNameApi({
      projectId: data.projectId,
      name: data.name
    }).then(() => {
      dispatch(executeAlert({
        message: 'Nome do projeto atualizado com sucesso',
        type: 'success'
      }))
      closeCreateProjectForm()
      setProjectList()
      stopLoading()
    })
  }, [setProjectList, startLoading, stopLoading, updateProjectNameApi, closeCreateProjectForm, dispatch])

  const updateProjectStatus = useCallback((data: {
    projectId: string,
    status: boolean
  }) => {
    startLoading()
    updateProjectStatusApi({
      projectId: data.projectId,
      status: data.status
    }).then(() => {
      setProjectList()
      stopLoading()
    })
  }, [setProjectList, startLoading, stopLoading, updateProjectStatusApi])

  const cloneProject = useCallback((data: {
    projectId: string
  }) => {
    startLoading()
    cloneProjectApi({
      projectId: data.projectId,
      createdBy: user.id,
      name: projectName,
    }).then(() => {
      setProjectList()
      stopLoading()
      closeCreateProjectForm()
    })
  }, [cloneProjectApi, setProjectList, startLoading, stopLoading, user.id, projectName, closeCreateProjectForm])

  const resetFilters = useCallback(() => {
    dispatch(setActiveFilterState({
      activeFilter: 0
    }))
    dispatch(setActiveDataSectionState({
      activeDataSection: 0
    }))
    dispatch(setProjectActiveSectionState({
      activeSection: 'filter'
    }))
    dispatch(setProjectReportState({
      projectReport: undefined
    }))
    if (selectedProject) {
      dispatch(setSelectedProjectState({
        selectedProject: {
          ...selectedProject,
          filters: [{
            id: selectedProject.filters[0].id,
            status: [
              'ATIVA'
            ],
            economicGroup: ['MATRIZ'],
            specialStatus: ['NOT_INCLUDE'],
            justPrimaryActivity: true
          }]
        }
      }))
    }
  }, [dispatch, selectedProject,])

  const addBlackListFilter = useCallback((data: {
    blackList: string
  }) => {
    dispatch(addBlackListFilterState({
      blackListId: data.blackList
    }))
  }, [dispatch])

  const removeBlackListFilter = useCallback((data: {
    blackList: string
  }) => {
    dispatch(removeBlackListFilterState({
      blackListId: data.blackList
    }))
  }, [dispatch])

  const addCepListFilter = useCallback((data: {
    cepList: string
  }) => {
    dispatch(addCepListFilterState({
      cepListId: data.cepList
    }))
  }, [dispatch])

  const removeCepListFilter = useCallback((data: {
    cepList: string
  }) => {
    dispatch(removeCepListFilterState({
      cepListId: data.cepList
    }))
  }, [dispatch])

  const addPurgeCepListFilter = useCallback((data: {
    cepList: string
  }) => {
    dispatch(addPurgeCepListFilterState({
      cepListId: data.cepList
    }))
  }, [dispatch])

  const removePurgeCepListFilter = useCallback((data: {
    cepList: string
  }) => {
    dispatch(removePurgeCepListFilterState({
      cepListId: data.cepList
    }))
  }, [dispatch])

  const setJustPrimaryActivityFilter = useCallback((data: {
    justPrimaryActivity: boolean
  }) => {
    dispatch(setJustPrimaryActivityFilterState({
      justPrimaryActivity: data.justPrimaryActivity
    }))
  }, [dispatch])

  const setFilterByLatAndLngCep = useCallback((data: {
    cep: string
  }) => {
    dispatch(setFilterByLatAndLngCepState({
      filterByLatAndLngCep: data.cep
    }))
  }, [dispatch])

  const setFilterByLatAndLngRadius = useCallback((data: {
    radius: number
  }) => {
    dispatch(setFilterByLatAndLngRadiusState({
      filterByLatAndLngRadius: data.radius
    }))
  }, [dispatch])

  const setFilterByLatAndLng = useCallback((data: {
    flag: boolean
  }) => {
    dispatch(setFilterByLatAndLngState({
      filterByLatAndLng: data.flag
    }))
  }, [dispatch])

  const addTermsFilter = useCallback((data: {
    terms: string
  }) => {
    dispatch(addTermFilterState({
      term: data.terms.toLowerCase()
    }))
  }, [dispatch])

  const removeTermFilter = useCallback((data: {
    terms: string
  }) => {
    dispatch(removeTermFilterState({
      term: data.terms.toLowerCase()
    }))
  }, [dispatch])

  const addNotIncludeTermsFilter = useCallback((data: {
    terms: string
  }) => {
    dispatch(addNotIncludeTermFilterState({
      term: data.terms.toLowerCase()
    }))
  }, [dispatch])

  const removeNotIncludeTermFilter = useCallback((data: {
    terms: string
  }) => {
    dispatch(removeNotIncludeTermFilterState({
      term: data.terms.toLowerCase()
    }))
  }, [dispatch])

  const setPage = useCallback((data: {
    page: number
  }) => {
    dispatch(setProjectPageState({
      page: data.page
    }))
  }, [dispatch])

  const setProjectFilterActive = useCallback((data: {
    active: boolean
  }) => {
    dispatch(setProjectActiveFilterState({
      active: !data.active
    }))
  }, [dispatch])

  const setPageSize = useCallback((data: {
    pageSize: number
  }) => {
    dispatch(setProjectPageSizeState({
      pageSize: data.pageSize
    })
    )
  }, [dispatch])

  const setShareCapitalMaxFilter = useCallback((data: {
    shareCapitalMax: number
  }) => {
    dispatch(setMaxShareCapitalFilterState({
      maxShareCapital: data.shareCapitalMax
    }))
  }, [dispatch])

  const setShareCapitalMinFilter = useCallback((data: {
    shareCapitalMin: number
  }) => {
    dispatch(setMinShareCapitalFilterState({
      minShareCapital: data.shareCapitalMin
    }))
  }, [dispatch])

  const addActiveDebtsFilter = useCallback((data: {
    activeDebt: IActiveDebt
  }) => {
    dispatch(addActiveDebtsFilterState({
      activeDebt: data.activeDebt
    }))
  }, [dispatch])

  const removeActiveDebtsFilter = useCallback((data: {
    activeDebt: IActiveDebt
  }) => {
    dispatch(removeActiveDebtsFilterState({
      activeDebt: data.activeDebt
    }))
  }, [dispatch])

  const setIncludeDebtsFilter = useCallback((data: {
    includeDebts: boolean
  }) => {
    dispatch(setIncludeDebtsFilterState({
      includeDebts: data.includeDebts
    }))
  }, [dispatch])

  const addCreditScoreDescriptionFilter = useCallback((data: {
    creditScoreDescription: ICreditScoreDescription
  }) => {
    dispatch(addCreditScoreDescriptionFilterState({
      creditScoreDescription: data.creditScoreDescription
    }))
  }, [dispatch])

  const removeCreditScoreDescriptionFilter = useCallback((data: {
    creditScoreDescription: ICreditScoreDescription
  }) => {
    dispatch(removeCreditScoreDescriptionFilterState({
      creditScoreDescription: data.creditScoreDescription
    }))
  }, [dispatch])

  const setGeolocationFilter = useCallback((data: {
    geolocation: {
      lat: number
      lon: number
      radius: number
    }
  }) => {
    dispatch(setGeolocationFilterState({
      geolocation: {
        lat: data.geolocation.lat,
        lon: data.geolocation.lon,
        radius: (data.geolocation.radius / 1000)
      }
    }))
  }, [dispatch])

  const setGeolocationFilterRadius = useCallback((data: {
    radius: number
  }) => {
    dispatch(setGeolocationFilterRadiusState({
      radius: (data.radius / 1000)
    }))
  }, [dispatch])

  const setUseGeolocationFilter = useCallback((data: {
    useGeolocationFilter: boolean
  }) => {
    dispatch(setUseGeolocationFilterState({
      useGeolocationFilter: data.useGeolocationFilter
    }))
  }, [dispatch])

  const resetTableFilters = useCallback(() => {
    dispatch(resetTableFiltersInitialState())
  }, [dispatch])

  const setTableFilterName = useCallback((data: {
    name: string
  }) => {
    dispatch(setTableFilterNameState({
      name: data.name
    }))
  }, [dispatch])

  const setTableFilterCreatedBy = useCallback((data: {
    createdBy: string
  }) => {
    dispatch(setTableFilterCreatedByState({
      createdBy: data.createdBy
    }))
  }, [dispatch])

  const setTableFilterStartDate = useCallback((data: {
    startDate?: Date
  }) => {
    dispatch(setTableFilterStartDateState({
      startDate: data.startDate
    }))
  }, [dispatch])

  const setTableFilterEndDate = useCallback((data: {
    endDate?: Date
  }) => {
    dispatch(setTableFilterEndDateState({
      endDate: data.endDate
    }))
  }, [dispatch])

  const addNaturezaJuridicaFilter = useCallback((data: {
    naturezaJuridica: INaturezaJuridica,
    action: 'include' | 'exclude'
  }) => {
    dispatch(setAddNaturezaJuridicaFilterState({
      naturezaJuridica: data.naturezaJuridica,
      action: data.action
    }))
  }, [dispatch])

  const removeNaturezaJuridicaFilter = useCallback((data: {
    naturezaJuridica: INaturezaJuridica,
    action: 'include' | 'exclude'
  }) => {
    dispatch(setRemoveNaturezaJuridicaFilterState({
      naturezaJuridica: data.naturezaJuridica,
      action: data.action
    }))
  } , [dispatch] )


  return {
    getCurrentProjectReport,
    deleteCountReport,
    setCountReportList,
    createCountReport,
    updateCountReport,
    setCountReportModalAction,
    setActiveDataSection,
    openExportDialog,
    closeExportDialog,
    updateProjectFilter,
    setProjectList,
    openProject,
    setActiveSection,
    setActiveFilter,
    addLocationFilter,
    removeLocationFilter,
    addSectorFilter,
    removeSectorFilter,
    addSegmentoFilter,
    removeSegmentoFilter,
    addEconomicActivityFilter,
    removeEconomicAcivityFilter,
    addIncludeBusinessSizeFilter,
    removeIncludeBusinessSizeFilter,
    addNotIncludeBusinessSizeFilter,
    removeNotIncludeBusinessSizeFilter,
    setRevenueMaxFilter,
    setRevenueMinFilter,
    setMaxEmployeeFilter,
    setMinEmployeeFilter,
    setMaxBusinessAssociateFilter,
    setMinBusinessAssociateFilter,
    addStatusFilter,
    removeStatusFilter,
    addEconomicGroupFilter,
    removeEconomicGroupFilter,
    setMaxCreatedAtFilter,
    setMinCreatedAtFilter,
    addBusinessContactFilter,
    removeBusinessContactFilter,
    addPartnetContactFilter,
    removePartnerContactFilter,
    addSpecialStatusFilter,
    removeSpecialStatusFilter,
    setAction,
    exportProject,
    setExportDialogName,
    setExportDialogTotal,
    closeProject,
    selectCountReport,
    openCountReportModal,
    closeCountReportModal,
    downloadCountReport,
    createProject,
    projectIsExporting,
    addPurgeLocationFilter,
    removePurgeLocationFilter,
    setProjectName,
    openCreateProjectForm,
    closeCreateProjectForm,
    updateProjectStatus,
    cloneProject,
    resetFilters,
    addBlackListFilter,
    removeBlackListFilter,
    addCepListFilter,
    removeCepListFilter,
    addPurgeCepListFilter,
    removePurgeCepListFilter,
    setJustPrimaryActivityFilter,
    updateProjectName,
    setFilterByLatAndLngCep,
    setFilterByLatAndLngRadius,
    setFilterByLatAndLng,
    addTermsFilter,
    removeTermFilter,
    addNotIncludeTermsFilter,
    removeNotIncludeTermFilter,
    addRegimeTributarioFilter,
    removeRegimeTributarioFilter,
    setPage,
    setProjectFilterActive,
    setPageSize,
    setShareCapitalMaxFilter,
    setShareCapitalMinFilter,
    addActiveDebtsFilter,
    removeActiveDebtsFilter,
    setIncludeDebtsFilter,
    addCreditScoreDescriptionFilter,
    removeCreditScoreDescriptionFilter,
    setGeolocationFilter,
    setGeolocationFilterRadius,
    setUseGeolocationFilter,
    resetTableFilters,
    setTableFilterName,
    setTableFilterCreatedBy,
    setTableFilterStartDate,
    setTableFilterEndDate,
    addNaturezaJuridicaFilter,
    removeNaturezaJuridicaFilter
  }
}