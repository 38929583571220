import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Dialog, Divider, FormControlLabel, Grid, List, ListItem, ListItemButton, ListItemText, Slide, Switch, TextField, Toolbar, Typography } from "@mui/material"
import { FilterHeader } from "../filter-header"
import { NaturezaJuridicaFilterPreview } from "./natureza-juridica-filter-preview/NaturezaJuridicaFilterPreview"
import AddIcon from '@mui/icons-material/Add';
import { forwardRef, useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNaturezaJuridicaService } from "../../../../../../use-cases/natureza-juridica";
import { useAppSelector } from "../../../../../../application/states/hooks";
import { useProjectService } from "../../../../../../use-cases/project";
import { NaturezaJuridicaFilterNotIncludePreview } from "./natureza-juridica-filter-preview/NaturezaJuridicaFilterNotIncludePreview";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const NaturezaJuridicaFilter = () => {
  const [open, setOpen] = useState(false)
  const [include, setInclude] = useState(true)
  const naturezaJuridicaState = useAppSelector((state) => state.naturezaJuridica)
  const naturezaJuridicaFilterState = useAppSelector((state) => state.project.selectedFilter?.naturezaJuridica)
  const { addNaturezaJuridicaFilter } = useProjectService()
  const { setSearchTerm, setNaturezaJuridicaList } = useNaturezaJuridicaService()
  // const projectState = useAppSelector((state) => state.project)
  useEffect(() => {
    setNaturezaJuridicaList()
  }, [setNaturezaJuridicaList])

  const handleSegmentosFilterClose = () => {
    setOpen(false)
    setSearchTerm({
      searchTerm: ''
    })
  }

  return <>
    <FilterHeader title="Natureza Jurídica" description={<>
      <Typography style={{
        marginBottom: '10px'
      }}>
        A natureza jurídica é o tipo legal da empresa, ou seja, a forma como ela está registrada no governo. Isso indica se a empresa é de um único dono, tem vários sócios ou é uma organização sem fins lucrativos. Cada tipo tem regras e impostos diferentes      </Typography>
      <Typography>
        Nesse filtro, você pode selecionar opções para incluir ou excluir termos na sua busca.
      </Typography>

    </>} />
    <Box my={3} mt={5}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button onClick={() => {
            setOpen(true)
          }} fullWidth endIcon={<>
            <AddIcon />
          </>}>
            <Typography variant="h6" fontWeight="bold">

              Clique para adicionar uma nova natureza jurídica
            </Typography>
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold" color="primary">
            Incluir Natureza Jurídica
          </Typography>
          <NaturezaJuridicaFilterPreview />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold" color="secondary">
            Não incluir Natureza Jurídica
          </Typography>
          <NaturezaJuridicaFilterNotIncludePreview />
        </Grid>
      </Grid>
    </Box >
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        handleSegmentosFilterClose()
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textTransform="uppercase">
            Selecionar natureza jurídica
          </Typography>
          <Button autoFocus color="inherit" onClick={() => {
            handleSegmentosFilterClose()
          }}>
            Fechar
          </Button>
        </Toolbar>
      </AppBar>
      <Box p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel control={<Switch
              checked={include}
              onChange={(e) => {
                setInclude(e.target.checked)
              }}
            />} label={
              include ? 'Incluir Natureza Jurídica' : 'Não incluir Natureza Jurídica'
            } />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={naturezaJuridicaState.searchTerm}
              onChange={(e) => {
                setSearchTerm({
                  searchTerm: e.target.value
                })
              }}
              fullWidth
              label="Digite o nome da natureza jurídica"
              placeholder="Digite o nome da natureza jurídica"
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <List >
            {
              naturezaJuridicaState.naturezaJuridicaList.map((naturezaJuridica, index) => {
                if (!naturezaJuridica.descricao?.toUpperCase().includes(naturezaJuridicaState.searchTerm.toUpperCase())) {
                  return false
                }

                // if (projectState.selectedFilter?.naturezaJuridica?.include?.includes(naturezaJuridica.)) {
                //   return false
                // }
                // if()

                return (<>
                  <div key={index}>
                    <ListItem
                      style={{
                        padding: 0
                      }}
                    >
                      <ListItemButton onClick={(e) => {
                        addNaturezaJuridicaFilter({
                          action: include ? 'include' : 'exclude',
                          naturezaJuridica: naturezaJuridica
                        })
                      }}>
                        <ListItemText primary={naturezaJuridica.descricao?.toUpperCase()} />

                      </ ListItemButton>
                    </ListItem>
                    <Divider />
                  </div>
                </>)
              })
            }

          </List>
        </Grid>
        {
          ((naturezaJuridicaFilterState?.include?.length ?? 9) > 0 ||
            (naturezaJuridicaFilterState?.notInclude?.length ?? 9) > 0
          ) && <Grid xs={6}>
            <Box p={3} my={3}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h5" color="primary" fontWeight="bold" textTransform="uppercase">
                    Incluir Natureza Jurídica
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <NaturezaJuridicaFilterPreview />
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box p={3} my={3}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h5" color="secondary" fontWeight="bold" textTransform="uppercase">
                    Não incluir Natureza Jurídica
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <NaturezaJuridicaFilterNotIncludePreview />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

        }


      </Grid>



    </Dialog>
  </>
}