// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  max-width: 100%;
}

.MuiDataGrid-columnHeaders {
  color: #fff;
  background-color: #723989;
}


.MuiDataGrid-columnHeaders * {
  color: #fff;
}

.leaflet-container {
  width: 100%;
  height: 80vh;
  z-index: 1;
}

div[class="leaflet-marker-icon marker-icon leaflet-zoom-animated leaflet-interactive leaflet-marker-draggable leaflet-pm-draggable"] {
  display: none;
}

.muidatagrid-cell:focus {
  outline: 0
}`, "",{"version":3,"sources":["webpack://./src/presentation/assets/css/image.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;;AAGA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;AACF","sourcesContent":["img {\n  max-width: 100%;\n}\n\n.MuiDataGrid-columnHeaders {\n  color: #fff;\n  background-color: #723989;\n}\n\n\n.MuiDataGrid-columnHeaders * {\n  color: #fff;\n}\n\n.leaflet-container {\n  width: 100%;\n  height: 80vh;\n  z-index: 1;\n}\n\ndiv[class=\"leaflet-marker-icon marker-icon leaflet-zoom-animated leaflet-interactive leaflet-marker-draggable leaflet-pm-draggable\"] {\n  display: none;\n}\n\n.muidatagrid-cell:focus {\n  outline: 0\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
