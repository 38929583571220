import { Grid, List, Typography, Box, Avatar, Chip, Paper } from "@mui/material"
import { useAppSelector } from "../../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../../use-cases/project";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt'
import { theme } from "../../../../../../styles";

export const LocationFilterPreview = () => {
  const projectState = useAppSelector((state) => state.project)
  const { removeLocationFilter, removePurgeLocationFilter, removeCepListFilter, removePurgeCepListFilter } = useProjectService()
  const cepListState = useAppSelector((state) => state.cepList)

  return (
    <>
      <List>
        {
          (projectState?.selectedFilter?.location?.length ?? 0) || (projectState?.selectedFilter?.cepLists?.length ?? 0) > 0 ? (
            <>
              <Paper elevation={2} sx={{ my: 2, p: 2 }} >
                <Box mb={1}>
                  <Typography variant="h6" color="primary" fontWeight={"bold"}>
                    Incluir Localização
                  </Typography>
                </Box>
                {
                  (projectState?.selectedFilter?.location?.length ?? 0) > 0 ? (
                    <Box my={3}>
                      <Typography color="primary" fontWeight={"bold"}>
                        Filtros de localização selecionados:
                      </Typography>
                    </Box>
                  ) : null
                }
                <Grid container spacing={3}>
                  {projectState.selectedFilter?.location?.map((location, index) => {
                    return (
                      <Grid item key={index} marginBottom={2}>
                        <Chip
                          key={index}
                          sx={{
                            height: 'auto',
                            '& .MuiChip-label': {
                              transition: '0.1s all',
                              display: 'block',
                              whiteSpace: 'normal',
                              padding: '10px 15px',
                              fontWeight: 'bold'
                            },
                          }}
                          color="primary"
                          onDelete={() => {
                            removeLocationFilter({
                              location: location
                            })
                          }}
                          avatar={
                            <Avatar
                              key={index}
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              <LocationOnIcon
                                key={index}
                                sx={{
                                  "&": {
                                    transition: '0.1s all'
                                  },
                                  "&:hover": {
                                    color: "secondary.main"
                                  },
                                  backgroundColor: theme.palette.primary.main,
                                  color: theme.palette.primary.contrastText
                                }}
                              />
                            </Avatar>
                          }
                          label={location.value}
                        />
                      </Grid>
                    )

                  })}
                </Grid>
                {
                  (projectState?.selectedFilter?.cepLists?.length ?? 0) > 0 ? (
                    < Box my={3}>
                      <Typography color="primary" fontWeight={"bold"}>
                        Listas com CEPs selecionadas:
                      </Typography>
                    </Box>
                  ) : null
                }
                <Grid container spacing={3}>
                  {projectState.selectedFilter?.cepLists?.map((cepList, index) => {
                    return (
                      <Grid item key={index} marginBottom={2}>
                        <Chip
                          key={index}
                          sx={{
                            height: 'auto',
                            '& .MuiChip-label': {
                              transition: '0.1s all',
                              display: 'block',
                              whiteSpace: 'normal',
                              padding: '10px 15px',
                              fontWeight: 'bold',
                              color: theme.palette.primary.contrastText
                            }
                          }}
                          color="primary"
                          onDelete={() => {
                            removeCepListFilter({
                              cepList: cepList
                            })
                          }}
                          avatar={
                            <Avatar
                              key={index}
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              <EditLocationAltIcon
                                key={index}
                                sx={{
                                  "&": {
                                    transition: '0.1s all'
                                  },
                                  "&:hover": {
                                    color: "secondary.main"
                                  },
                                  backgroundColor: theme.palette.primary.main,
                                  color: theme.palette.primary.contrastText
                                }} />
                            </Avatar>
                          }
                          label={cepListState.cepLists.find((cepListState) => cepListState.id === cepList)?.name.toUpperCase()} />
                      </Grid>
                    )
                  })}
                </Grid>
              </Paper>
            </>
          ) : null
        }
        {
          (projectState?.selectedFilter?.purgeLocation?.length ?? 0) || (projectState?.selectedFilter?.purgeCepLists?.length ?? 0) > 0 ? (
            <>
              <Paper elevation={2} sx={{ my: 2, p: 2 }} >
                <Box mb={1}>
                  <Typography variant="h6" color="secondary" fontWeight={"bold"}>
                    Não Incluir Localização
                  </Typography>
                </Box>
                {
                  (projectState?.selectedFilter?.purgeLocation?.length ?? 0) > 0 ? (
                    <Box my={3}>
                      <Typography color="secondary" fontWeight={"bold"}>
                        Filtros de localização selecionados:
                      </Typography>
                    </Box>
                  ) : null
                }
                <Grid container spacing={3}>
                  {projectState.selectedFilter?.purgeLocation?.map((location, index) => {
                    return (
                      <Grid item key={index} marginBottom={2}>
                        <Chip sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            transition: '0.1s all',
                            display: 'block',
                            whiteSpace: 'normal',
                            padding: '10px 15px',
                            fontWeight: 'bold',
                            color: '#FFF'
                          }
                        }}
                          color="secondary"
                          onDelete={() => {
                            removePurgeLocationFilter({
                              location: location
                            })
                          }}
                          avatar={
                            <Avatar style={{
                              cursor: 'pointer'
                            }}>
                              <LocationOnIcon sx={{
                                "&": {
                                  transition: '0.1s all'
                                },
                                "&:hover": {
                                  color: "primary.main"
                                },
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.secondary.contrastText
                              }} />
                            </Avatar>}
                          label={location.value} />
                      </Grid>
                    )
                  })}
                </Grid>
                {
                  (projectState?.selectedFilter?.purgeCepLists?.length ?? 0) > 0 ? (
                    <Box my={3}>
                      <Typography color="secondary" fontWeight={"bold"}>
                        Listas com CEPs selecionadas:
                      </Typography>
                    </Box>
                  ) : null
                }
                <Grid container spacing={3}>
                  {projectState.selectedFilter?.purgeCepLists?.map((cepList, index) => {
                    return (
                      <Grid item key={index} marginBottom={2}>
                        <Chip sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            transition: '0.1s all',
                            display: 'block',
                            whiteSpace: 'normal',
                            padding: '10px 15px',
                            fontWeight: 'bold'
                          }
                        }}
                          color="secondary"
                          onDelete={() => {
                            removePurgeCepListFilter({
                              cepList: cepList
                            })
                          }}
                          avatar={
                            <Avatar style={{
                              cursor: 'pointer'
                            }}>
                              <EditLocationAltIcon sx={{
                                "&": {
                                  transition: '0.1s all'
                                },
                                "&:hover": {
                                  color: "primary.main"
                                },
                                backgroundColor: theme.palette.secondary.main
                              }} />
                            </Avatar>}
                          label={cepListState.cepLists.find((cepListState) => cepListState.id === cepList)?.name.toUpperCase()} />
                      </Grid>
                    )
                  })}
                </Grid>
              </Paper>
            </>
          ) : null
        }
      </List >
    </>
  )
}