import { List, Grid, Chip, Avatar } from "@mui/material"
import { theme } from "../../../../../../styles"
import GavelIcon from '@mui/icons-material/Gavel';
import { useAppSelector } from "../../../../../../../application/states/hooks";
import { useProjectService } from "../../../../../../../use-cases/project";

export const NaturezaJuridicaFilterNotIncludePreview = () => {
  const naturezaJuridicaFilter = useAppSelector ((state) => state.project.selectedFilter?.naturezaJuridica)
  const { removeNaturezaJuridicaFilter } = useProjectService()
  return <>

    <List>
      <Grid container spacing={3}>
        {naturezaJuridicaFilter?.notInclude?.map((naturezaJuridica, index) => {
          return (
            <Grid item key={index}>
              <Chip sx={{
                height: 'auto',
                '& .MuiChip-label': {
                  transition: '0.1s all',
                  display: 'block',
                  whiteSpace: 'normal',
                  padding: '10px 15px',
                  fontWeight: 'bold'
                }
              }}
                color="secondary"
                onDelete={() => {
                  removeNaturezaJuridicaFilter({
                    naturezaJuridica: naturezaJuridica,
                    action: 'exclude'
                  })
                }}
                avatar={
                  <Avatar
                    style={{
                      cursor: 'pointer',
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.secondary.contrastText
                    }}>

                    <GavelIcon sx={{
                      "&": {
                        transition: '0.1s all'
                      },
                      "&:hover": {
                        color: "primary.main"
                      }
                    }} />
                  </Avatar>
                } label={naturezaJuridica.descricao} />
            </Grid>
          )
        })}
      </Grid>
    </List >
  </>
}