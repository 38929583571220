import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { executeAlert } from "../../application/states/toast-alert"
import { addEmailFilterState, removeEmailFilterState, selectSellerState, selectUserState, setChangePasswordConfirmValueState, setChangePasswordValueState, setSelectedUserImageFileState, setUserEmailState, setUserImageFileInputValueState, setUserListState, setUserNameState, setUserSearchTermState, setUserState } from "../../application/states/user/user-slice"
import { useResaleConfigApi, useUserApi } from "../../infra"
import { decodeJwt } from 'jose'
import { IUser } from "../../application/models"
import { useCompanyApi } from "../../infra/company-api/useCompanyApi"
import { setCompanyState } from "../../application/states/company"
import { setResaleConfigState } from "../../application/states/resale-config"
import { useLoadingService } from "../loading"
import { resetSelectedCompanyState, setSelectedCompanyState } from "../../application/states/backoffice/company"

function validatePassword(value: string) {
  let errorMessage: string | undefined = undefined
  let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
  if (!regex.test(value)) {
    errorMessage = 'A senha deve conter pelo menos 8 caracteres, sendo pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.'
  }

  if (errorMessage) {
    return {
      type: 'Senha',
      message: errorMessage
    }
  }
  return undefined
}

export const useUserService = () => {
  const dispatch = useAppDispatch()
  // Infra
  const { createUserApi, updateUserApi, findById, changeUserEmailApi, changeUserNameApi, changeUserPasswordApi, findUserByCompanyIdApi } = useUserApi()
  const { findCompanyById } = useCompanyApi()
  const { findResaleConfigByCompanyIdApi, findResaleConfigByResaleIdApi } = useResaleConfigApi()
  // Service
  const { startLoading, stopLoading } = useLoadingService()
  // State
  const { token } = useAppSelector((state) => state.auth)
  const { user } = useAppSelector((state) => state)
  const { selectedCompany } = useAppSelector((state) => state.backofficeCompany)

  const setUser = useCallback(() => {
    let decoded: any = {}
    if (token) {
      try {
        decoded = decodeJwt(token)
      } catch (err) {
      }

      findById({
        userId: (decoded as any)?.id
      }).then((user) => {
        dispatch(setUserState({
          user: user
        }))
        return user
      }).then((res) => {
        findCompanyById({
          companyId: res.companyId
        }).then((company) => {
          dispatch(setCompanyState({
            active: company.active,
            cnpj: company.cnpj,
            createdAt: company.createdAt,
            email: company.email,
            id: company.id,
            phone: company.phone,
            razaoSocial: company.razaoSocial,
            updatedAt: company.updatedAt,
            img: company.img,
            nomeFantasia: company.nomeFantasia,
            master: company.master,
            resale: company.resale,
            resaleId: company.resaleId
          }))

          return company
        }).then((company) => {
          if (company.resale === true) {
            findResaleConfigByCompanyIdApi({
              companyId: company.id
            }).then((resaleConfig) => {
              dispatch(setResaleConfigState({
                resaleConfig: resaleConfig
              }))
            })
          } else if (company.resaleId) {
            findResaleConfigByResaleIdApi({
              resaleId: company.resaleId
            }).then((resaleConfig) => {
              dispatch(setResaleConfigState({
                resaleConfig: resaleConfig
              }))
            })
          }
        })
      }).catch((error) => {
        dispatch(executeAlert({
          message: 'Erro ao buscar usuário',
          type: 'error'
        }))
      })
    }
  }, [token, findById, dispatch, findCompanyById, findResaleConfigByCompanyIdApi, findResaleConfigByResaleIdApi])

  const setUserEmail = useCallback((data: {
    email: string
  }) => {
    dispatch(setUserEmailState({
      email: data.email
    }))

  }, [dispatch])

  const setUserName = useCallback((data: {
    name: string
  }) => {
    dispatch(setUserNameState({
      name: data.name
    }))
  }, [dispatch])

  const changeUserEmail = useCallback(() => {
    startLoading()
    changeUserEmailApi({
      email: user.email,
      userId: user.id
    }).then((user) => {
      dispatch(setUserState({
        user: user
      }))
      dispatch(executeAlert({
        message: 'Email alterado com sucesso',
        type: 'success'
      }))
      stopLoading()
    }).catch((error) => {
      dispatch(executeAlert({
        message: error.message,
        type: 'error'
      }))
      stopLoading()
    })
  }, [changeUserEmailApi, dispatch, startLoading, stopLoading, user.email, user.id])

  const changeUserName = useCallback(() => {
    startLoading()
    changeUserNameApi({
      name: user.name,
      userId: user.id
    }).then((user) => {
      dispatch(setUserState({
        user: user
      }))
      dispatch(executeAlert({
        message: 'Nome alterado com sucesso',
        type: 'success'
      }))
      stopLoading()
    }).catch((error) => {
      dispatch(executeAlert({
        message: error.message,
        type: 'error'
      }))
      stopLoading()
    })
  }, [changeUserNameApi, dispatch, startLoading, stopLoading, user.id, user.name])

  const setChangePasswordValue = useCallback((data: {
    value: string
  }) => {
    dispatch(setChangePasswordValueState({
      value: data.value
    }))
  }, [dispatch])

  const setChangePasswordConfirmationValue = useCallback((data: {
    value: string
  }) => {
    dispatch(setChangePasswordConfirmValueState({
      value: data.value
    }))
  }, [dispatch])


  const changeUserPassword = useCallback(() => {
    if (user.changePasswordValue !== user.changePasswordConfirmValue) {
      dispatch(executeAlert({
        message: 'As senhas não conferem',
        type: 'error'
      }))
    } else {
      let validatedPasswordResult = validatePassword(user.changePasswordValue)
      if (validatedPasswordResult) {
        dispatch(executeAlert({
          message: validatedPasswordResult.message ?? 'Erro ao validar senha',
          type: 'error'
        }))
        return
      }
      startLoading()
      changeUserPasswordApi({
        userId: user.id,
        password: user.changePasswordValue
      }).then((user) => {
        setChangePasswordValue({
          value: ''
        })
        setChangePasswordConfirmationValue({
          value: ''
        })

        dispatch(setUserState({
          user: user
        }))
        dispatch(executeAlert({
          message: 'Senha alterada com sucesso',
          type: 'success'
        }))
        stopLoading()
      }).catch((error) => {
        dispatch(executeAlert({
          message: error.message,
          type: 'error'
        }))
        stopLoading()
      })
    }
  }, [user.changePasswordValue, user.changePasswordConfirmValue, user.id, dispatch, changeUserPasswordApi, startLoading, setChangePasswordValue, setChangePasswordConfirmationValue, stopLoading])

  const setUserList = useCallback(async () => {
    if (user.userType === 'ADMIN' || user.userType === 'OWNER') {
      startLoading()
      findUserByCompanyIdApi({
        companyId: user.companyId
      }).then((users) => {
        dispatch(setUserListState({
          userList: users
        }))
        stopLoading()
      }).catch((error) => {
        dispatch(executeAlert({
          message: error.message,
          type: 'error'
        }))
        stopLoading()
      }
      )
    } else {
      dispatch(setUserListState({
        userList: [
          {
            companyId: user.companyId,
            createdAt: user.createdAt,
            email: user.email,
            id: user.id,
            name: user.name,
            status: true,
            updatedAt: user.updatedAt,
            userType: user.userType,
            img: user.img
          }
        ]
      }))
    }
  }, [dispatch, findUserByCompanyIdApi, startLoading, stopLoading, user.companyId, user.createdAt, user.email, user.id, user.img, user.name, user.updatedAt, user.userType])

  const selectUser = useCallback((data: {
    user: IUser
  }) => {
    dispatch(selectUserState({
      user: data.user
    }))
  }, [dispatch])

  const updateUser = useCallback(() => {
    if (user.selectedUser) {

      updateUserApi({
        email: user.selectedUser.email,
        name: user.selectedUser.name,
        userId: user.selectedUser.id,
        userType: user.selectedUser.userType,
        status: user.selectedUser.status,
        image: user.selectedUser.img
      }).then((user) => {
        setUserList()
        dispatch(executeAlert({
          message: 'Usuário atualizado com sucesso',
          type: 'success'
        }))
      }).catch((error) => {
        dispatch(executeAlert({
          message: error.message,
          type: 'error'
        }))
      })
    }
  }, [user.selectedUser, updateUserApi, setUserList, dispatch])

  const createUser = useCallback(() => {
    if (user.selectedUser) {
      startLoading()
      createUserApi({
        email: user.selectedUser.email,
        name: user.selectedUser.name,
        companyId: user.companyId,
        userType: user.selectedUser.userType,
        img: user.selectedUser.img
      }).then((user) => {
        setUserList()
        dispatch(executeAlert({
          message: 'Usuário criado com sucesso!',
          type: 'success'
        }))
        stopLoading()
      }).catch((error) => {
        dispatch(executeAlert({
          message: error.message,
          type: 'error'
        }))
        stopLoading()
      })
    }
  }, [createUserApi, dispatch, setUserList, startLoading, stopLoading, user.companyId, user.selectedUser])

  const setSelectedUserFileImage = useCallback((newFile: File | null) => {
    if (newFile === null) {
      dispatch(setSelectedUserImageFileState({
        image: undefined
      }))
      if (user.selectedUser) {
        selectUser({
          user: {
            ...user.selectedUser,
            img: undefined
          }
        })
        return
      }
    }

    if (newFile!.type !== 'image/png' && newFile!.type !== 'image/jpg' && newFile!.type !== 'image/jpeg' && newFile!.type !== 'image/bmp') {
      dispatch(executeAlert({
        message: 'Insira um arquivo .PNG, .JPG ou .JPEG.',
        type: 'error'
      }))
      return
    }


    if (newFile!.size > 5000000) {
      dispatch(executeAlert({
        message: "Arquivo muito grande. Insira um arquivo com no máximo 5MB.",
        type: "error"
      }))
      return
    }

    let fileReader = new FileReader()
    let isCancel = false
    if (newFile) {
      fileReader.onload = (e: any) => {
        if (fileReader && fileReader.readyState === 1) {
          fileReader.abort()
          return
        }
        const { result } = e.target
        if (result && !isCancel) {
          if (user.selectedUser) {
            selectUser({
              ...user.selectedUser,
              user: {
                ...user.selectedUser,
                img: result
              }
            })
          }
        }
        dispatch(setSelectedUserImageFileState({
          image: result
        }))
        dispatch(executeAlert({
          message: 'Imagem inserida com sucesso.',
          type: 'success'
        }))
      }
      fileReader.readAsDataURL(newFile)
    }
  }, [dispatch, user.selectedUser, selectUser])

  const setUserImageFileInputValue = useCallback((newFile: File | null) => {

    if (newFile === null) {
      dispatch(setUserImageFileInputValueState({
        image: undefined
      }))
    }

    if (newFile!.type !== 'image/png' && newFile!.type !== 'image/jpg' && newFile!.type !== 'image/jpeg' && newFile!.type !== 'image/bmp') {
      dispatch(executeAlert({
        message: 'Insira um arquivo .PNG, .JPG ou .JPEG.',
        type: 'error'
      }))
      return
    }

    if (newFile!.size > 5000000) {
      dispatch(executeAlert({
        message: "Arquivo muito grande. Insira um arquivo com no máximo 5MB.",
        type: "error"
      }))
      return
    }

    let fileReader = new FileReader()
    if (newFile) {
      fileReader.onload = (e: any) => {
        if (fileReader && fileReader.readyState === 1) {
          fileReader.abort()
          return
        }
        const { result } = e.target
        dispatch(setUserImageFileInputValueState({
          image: result
        }))
        dispatch(executeAlert({
          message: 'Imagem inserida com sucesso.',
          type: 'success'
        }))
      }
      fileReader.readAsDataURL(newFile)
    }
  }, [dispatch])

  const changeUserImageFile = useCallback(() => {
    startLoading()
    updateUserApi({
      email: user.email,
      name: user.name,
      userId: user.id,
      userType: user.userType,
      image: user.userImageFileInputValue
    }).then((user) => {
      setUser()
      dispatch(executeAlert({
        message: 'Imagem atualizada com sucesso',
        type: 'success'
      }))
      stopLoading()
    }).catch((error) => {
      dispatch(executeAlert({
        message: error.message,
        type: 'error'
      }))
      stopLoading()
    })
  }, [dispatch, setUser, startLoading, stopLoading, updateUserApi, user.email, user.id, user.name, user.userImageFileInputValue, user.userType])

  const setUserSearchTerm = useCallback((data: {
    searchTerm: string
  }) => {
    dispatch(setUserSearchTermState({
      searchTerm: data.searchTerm
    }))
  }, [dispatch])

  const addEmailFilter = useCallback((data: {
    email: string
  }) => {
    dispatch(addEmailFilterState({
      email: data.email
    }))
  }, [dispatch])

  const removeEmailFilter = useCallback((data: {
    email: string
  }) => {
    dispatch(removeEmailFilterState({
      email: data.email
    }))
  }, [dispatch])

  const selectSeller = useCallback((data: {
    userId: string
  }) => {
    const { userId } = data
    const findUser = user.userList.find((user) => user.id === userId)
    if (findUser) {
      dispatch(selectSellerState({
        seller: findUser
      }))
    }
  }, [dispatch, user.userList])

  const createUserForCompanies = useCallback(() => {
    if (user.selectedUser) {
      if (selectedCompany) {
        startLoading()
        createUserApi({
          email: user.selectedUser.email,
          name: user.selectedUser.name,
          companyId: selectedCompany.company.id,
          userType: user.selectedUser.userType,
          img: user.selectedUser.img
        }).then((user) => {
          findUserByCompanyIdApi({
            companyId: user.companyId
          }).then((users) => {

            const orderedUsers = [...users].sort((a, b) => {
              if (a.name < b.name) {
                return -1
              } else {
                return 1
              }
            })

            dispatch(setSelectedCompanyState({
              company: selectedCompany.company,
              separatedCredits: selectedCompany.separatedCredits,
              subscription: selectedCompany.subscription,
              users: orderedUsers,
              userListWithConsumption: selectedCompany.consumptions?.userListWithConsumption
            }))
          }).catch((error) => {
            dispatch(executeAlert({
              message: error.message,
              type: 'error'
            }))
            stopLoading()
          })

          stopLoading()
        }).catch((error) => {
          dispatch(executeAlert({
            message: error.message,
            type: 'error'
          }))
          dispatch(resetSelectedCompanyState())
          stopLoading()
        })

        dispatch(executeAlert({
          message: 'Usuário criado com sucesso',
          type: 'success'
        }))
      }
    }
  }, [createUserApi, dispatch, findUserByCompanyIdApi, selectedCompany, startLoading, stopLoading, user.selectedUser])

  return {
    setUser,
    setUserEmail,
    setUserName,
    changeUserEmail,
    changeUserName,
    setChangePasswordValue,
    setChangePasswordConfirmationValue,
    changeUserPassword,
    setUserList,
    selectUser,
    updateUser,
    createUser,
    setSelectedUserFileImage,
    setUserImageFileInputValue,
    changeUserImageFile,
    selectSeller,
    setUserSearchTerm,
    addEmailFilter,
    removeEmailFilter,
    createUserForCompanies
  }
}