// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  border: 2px dashed #ccc;
  border-radius: 8px;
  margin: 20px 0;
  font-size: 1.2rem;
  color: #555;
  text-align: center;
}

.file-upload.dragging {
  background-color: #eee;
}

.file-upload p {
  margin: 0;
}

.file-upload:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/presentation/pages/workspace/data-enrichment/data-enrichment-form/file-upload/fileUploadStyle.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".file-upload {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 200px;\n  width: 100%;\n  border: 2px dashed #ccc;\n  border-radius: 8px;\n  margin: 20px 0;\n  font-size: 1.2rem;\n  color: #555;\n  text-align: center;\n}\n\n.file-upload.dragging {\n  background-color: #eee;\n}\n\n.file-upload p {\n  margin: 0;\n}\n\n.file-upload:hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
