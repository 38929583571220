import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { forwardRef, useEffect, useState } from "react"
import { AppBar, Box, Button, Dialog, Fab, Grid, Slide, TextField, Toolbar, Typography, useTheme } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { TransitionProps } from "@mui/material/transitions"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import ptBR from 'date-fns/locale/pt-BR';
import { darkenColor, dataGridTexts, formatCnpj, nameAbbreviation } from "../../../../../../utils"
import { useBackofficeService } from "../../../../../../use-cases/backoffice"
import { useAppSelector } from "../../../../../../application/states/hooks"
import { useUserService } from "../../../../../../use-cases/user"

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const resourceTypeToLabel = (type: string) => {
  switch (type) {
    case 'export':
      return 'Exportação'
    case 'unit-search':
      return 'Consulta unitária'
    case 'enrichment':
      return 'Enriquecimento'
    default:
      return 'Desconhecido'
  }
}

export const CompaniesTable = () => {
  // Theme
  const theme = useTheme()
  // State
  const [searchTerm, setSearchTerm] = useState('')
  const userState = useAppSelector((state) => state.user)
  const companies = useAppSelector((state) => state.backofficeCompany.companyList)
  const { company } = useAppSelector((state) => state.company)
  const consumptionReport = useAppSelector((state) => state.backofficeConsumption.report)
  const companyState = useAppSelector((state) => state.company.company)
  // Service
  const {
    openCompanyForm,
    selectCompany,
    getConsumptionReport,
    resetConsumptionReport
  } = useBackofficeService()
  const { setUserList } = useUserService()

  const consumptionReportCols: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Data',
      headerAlign: 'center',
      minWidth: 155,
      align: 'center',
      renderCell(params) {
        return (
          <div>
            {new Date(params.value as string).toLocaleString('pt-BR', {
              timeZone: 'America/Sao_Paulo'
            })}
          </div>
        )
      }
    },
    {
      field: 'type',
      headerName: 'Origem',
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      renderCell(params) {
        return (
          <div>
            {resourceTypeToLabel(params.value)}
          </div>
        )
      }
    },
    {
      field: 'user',
      headerName: 'Usuário',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return (
          <div>
            {params.value?.name ?? ''}
          </div>
        )
      }
    },
    {
      field: 'ip',
      headerName: 'IP',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return (
          <div>
            {params.value}
          </div>
        )
      }
    },
    {
      field: 'totalSeparatedCredits',
      headerName: 'Créditos separados',
      headerAlign: 'center',
      minWidth: 150,
      align: 'center',
      renderCell(params) {
        return (
          <div>
            {params.value.toLocaleString('pt-BR')}
          </div>
        )
      }
    },
    {
      field: 'totalSubscription',
      headerName: 'Créditos do plano',
      headerAlign: 'center',
      minWidth: 150,
      align: 'center',
      renderCell(params) {
        return (
          <div>
            {params.value.toLocaleString('pt-BR')}
          </div>
        )
      }
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 0.6,
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return (
          <div>
            {params.value.toLocaleString('pt-BR')}
          </div>
        )
      }
    }
  ]

  const columns: GridColDef[] = [
    {
      field: 'edit',
      headerName: 'Editar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 90,
      renderCell(params) {
        return (
          <div>
            <Button onClick={() => {
              setUserList()
              selectCompany(params.value)
              openCompanyForm()
            }}>
              <EditIcon />
            </Button>
          </div>
        )
      }
    },
    {
      field: 'subscription',
      headerName: 'Consumo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell(params) {
        return (
          <div>
            <Button onClick={() => {
              selectCompany(params.value)
              setOpenConsumptionInfo(true)
            }}>
              <AutoAwesomeIcon />
            </Button>
          </div>
        )
      }
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      minWidth: 180,
      renderCell(params) {
        return formatCnpj(params.value ?? '')
      }
    },
    {
      field: 'razaoSocial',
      headerName: 'Razão Social',
      flex: 1,
      minWidth: 115,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'nomeFantasia',
      headerName: 'Nome Fantasia',
      flex: 0.9,
      minWidth: 115,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'active',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      minWidth: 80,
      renderCell(params) {
        return (
          <div
            style={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              background: params.value ? theme.palette.success.main : theme.palette.error.main,
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)'
            }}
          />
        )
      }
    },
    {
      field: 'clientFrom',
      headerName: 'Cliente Lurik / Revenda',
      headerAlign: 'center',
      align: 'center',
      minWidth: 80
    }
  ]

  const selectedCompany = useAppSelector((state) => state.backofficeCompany.selectedCompany)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    let totalAux = 0
    for (let i = 0; i < (consumptionReport?.consumptionsList.length ?? 0); i++) {
      totalAux += consumptionReport?.consumptionsList[i].total ?? 0
    }

    setTotal(totalAux)
  }, [consumptionReport?.consumptionsList])

  const [openConsumptionInfo, setOpenConsumptionInfo] = useState(false);
  const [consumptionInfoStartDate, setConsumptionInfoStartDate] = useState<Date | null>(null);
  const [consumptionInfoEndDate, setConsumptionInfoEndDate] = useState<Date | null>(null);

  return (
    <>
      <Box pb={3}>
        <TextField
          fullWidth
          label="Pesquisar"
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
      </Box>
      <Box sx={{
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: theme.palette.primary.main,
          color: company?.resale === true || company?.resaleId
            ? theme.palette.primary.contrastText
            : '#FFF'
        },
        '& .MuiDataGrid-cell': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 0
        },
        minWidth: '100%'
      }}>
        <DataGrid
          showCellVerticalBorder
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          rowSelection={false}
          autoHeight={true}
          localeText={dataGridTexts}
          pagination={true}
          pageSizeOptions={[5, 10, 20]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5
              }
            }
          }}
          rowCount={companies.length}
          rows={companies.filter((item) => {
            if (searchTerm === '' || searchTerm === null || searchTerm === undefined) {
              return true
            } else if (
              item.company.razaoSocial.toLowerCase().includes(searchTerm.toLowerCase())
              || item.company.nomeFantasia?.toLowerCase().includes(searchTerm.toLowerCase())
              || item.company.cnpj.toLowerCase().includes(searchTerm.toLowerCase())
              || formatCnpj(item.company.cnpj.toLowerCase()).includes(searchTerm.toLowerCase())
            ) {
              return true
            }

            return false
          }).map((item) => {
            return {
              id: item.company.id,
              edit: item,
              subscription: item,
              cnpj: item.company.cnpj,
              razaoSocial: nameAbbreviation(item.company.razaoSocial),
              nomeFantasia: item.company.nomeFantasia ? nameAbbreviation(item.company.nomeFantasia) : null,
              active: item.company.active,
              clientFrom: item.company.resaleId ? 'REVENDA' : 'LURIK'
            }
          })}
          columns={columns.filter((column) => {
            if (companyState?.master === false) {
              if (
                userState.userType === "SELLER"
                || userState.userType === "OPERATOR"
              ) {
                return column.field !== 'clientFrom' && column.field !== 'edit'
              }

              return column.field !== 'clientFrom'
            }

            if (
              userState.userType === "SELLER"
              || userState.userType === "OPERATOR"
            ) {
              return column.field !== 'clientFrom' && column.field !== 'edit'
            }

            return column
          })}
          slotProps={{
            pagination: {
              style: {
                marginRight: '35%'
              }
            }
          }}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            minWidth: '100%'
          }}
        />
      </Box>
      {
        userState.userType === "ADMIN" || userState.userType === "OWNER"
          ? <Fab onClick={() => {
            setUserList()
            openCompanyForm()
            selectCompany({
              company: {
                id: '',
                updatedAt: new Date(),
                cnpj: '',
                razaoSocial: '',
                email: '',
                phone: '',
                active: true,
                createdAt: new Date(),
                address: {
                  city: '',
                  houseNumber: '',
                  postalCode: '',
                  state: '',
                  street: '',
                  neighbourhood: ''
                },
                master: false,
                resale: false
              },
              subscription: {
                id: '',
                name: '',
                createdAt: new Date(),
                companyId: '',
                partnerCols: false,
                updatedAt: new Date(),
                sellerId: '',
                createdBy: '',
                ranges: [],
                status: 'active',
              },
              separatedCredits: []
            })
          }}
            style={{
              position: 'fixed',
              bottom: '5%',
              right: '5%'
            }}
            color="primary"
            aria-label="add"
            sx={{
              '&:hover': {
                backgroundColor: darkenColor(theme.palette.primary.main, 10)
              }
            }}
          >
            <AddIcon style={{
              color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
            }} />
          </Fab>
          : null
      }
      <Dialog
        fullWidth
        maxWidth="xl"
        open={
          openConsumptionInfo
        }
        onClose={() => {
          setConsumptionInfoStartDate(null)
          setConsumptionInfoEndDate(null)
          setOpenConsumptionInfo(false)
          resetConsumptionReport()
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Relatório de consumo
            </Typography>
            <Button autoFocus color="inherit" onClick={() => {
              setConsumptionInfoStartDate(null)
              setConsumptionInfoEndDate(null)
              setOpenConsumptionInfo(false)
              resetConsumptionReport()
            }}>
              Fechar
            </Button>
          </Toolbar>
        </AppBar>
        <Box p={3}>
          <Grid container justifyContent={"center"} spacing={3}>
            <Grid item>
              <LocalizationProvider
                dateFormats={{
                  fullDate: 'dd/MM/yyyy',
                }}

                dateAdapter={AdapterDateFns}
                adapterLocale={ptBR}>
                <DatePicker
                  value={consumptionInfoStartDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      setConsumptionInfoStartDate(newValue)
                    }
                  }}
                  disableFuture
                  views={['year', 'month']}
                  label="Data inicial"
                  slotProps={{
                    day: {
                      sx: {
                        "&.MuiPickersDay-root.Mui-selected": {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          '&:hover': {
                            backgroundColor: darkenColor(theme.palette.primary.main, 10)
                          }
                        }
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <LocalizationProvider
                dateFormats={{
                  fullDate: 'dd/MM/yyyy',
                }}
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBR}>
                <DatePicker
                  value={consumptionInfoEndDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      setConsumptionInfoEndDate(newValue)
                    }
                  }}
                  disableFuture
                  views={['year', 'month']}
                  label="Data final"
                  slotProps={{
                    day: {
                      sx: {
                        "&.MuiPickersDay-root.Mui-selected": {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          '&:hover': {
                            backgroundColor: darkenColor(theme.palette.primary.main, 10)
                          }
                        }
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  '&:hover': {
                    backgroundColor: darkenColor(theme.palette.primary.main, 10)
                  },
                  color: theme.palette.primary.contrastText
                }}
                onClick={() => {
                  if (selectedCompany) {
                    getConsumptionReport({
                      startDate: consumptionInfoStartDate ?? undefined,
                      endDate: consumptionInfoEndDate ?? undefined,
                    })
                  }
                }}
              >
                Gerar relatório
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{
                fontSize: '1.1rem'
              }}>
                {
                  consumptionReport ?
                    consumptionReport?.consumptionsList.length > 0 ?
                      <>
                        Total sintetizado: <span style={{ fontWeight: 'bold' }}>{total.toLocaleString('pt-BR')}</span> |
                        {
                          consumptionReport?.subscriptionData?.usage?.type === 'PÓS PAGO' || consumptionReport?.subscriptionData?.usage?.type === 'FEE' ?
                            <>
                              {' Plano'}: <span style={{ fontWeight: 'bold' }}>{consumptionReport?.subscriptionData?.usage?.type ?? ''}</span> |
                              Créditos utilizados: <span style={{ fontWeight: 'bold' }}>{consumptionReport?.subscriptionData?.usage?.usedBySubscription.toLocaleString('pt-BR')} {consumptionReport?.subscriptionData?.usage?.limit ? `/ ${consumptionReport?.subscriptionData?.usage?.limit?.toLocaleString('pt-BR')}` : ''}</span>.
                            </>
                            :
                            <>
                              {' Plano'}: <span style={{ fontWeight: 'bold' }}>{consumptionReport?.subscriptionData?.usage?.type ?? ''}</span> |
                              Créditos utilizados: <span style={{ fontWeight: 'bold' }}>{consumptionReport?.subscriptionData?.usage?.usedBySeparated.toLocaleString('pt-br')} / {consumptionReport?.subscriptionData?.usage?.totalBySeparated.toLocaleString('pt-br')}</span>
                            </>
                        }
                      </>
                      : null
                    : null
                }
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: theme.palette.primary.main,
                color: company?.resale === true || company?.resaleId
                  ? theme.palette.primary.contrastText
                  : '#FFF'
              },
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 0
              },
              minWidth: '100%'
            }}>
              {
                consumptionReport ?
                  consumptionReport?.consumptionsList.length > 0 && (
                    <DataGrid
                      showCellVerticalBorder
                      disableColumnFilter
                      disableColumnMenu
                      rowSelection={false}
                      autoHeight={true}
                      localeText={
                        dataGridTexts
                      }
                      pagination={undefined}
                      rows={
                        consumptionReport.consumptionsList.map((item, index) => {
                          let totalSubscription = 0
                          let totalSeparatedCredits = 0
                          for (let i = 0; i < item.origins.length; i++) {
                            totalSubscription += item.origins[i].subscription.total
                            totalSeparatedCredits += item.origins[i].separatedCredits.total
                          }
                          return {
                            id: index,
                            createdAt: item.createdAt,
                            type: item.type,
                            user: item.user,
                            ip: item.ip,
                            totalSeparatedCredits: totalSeparatedCredits,
                            totalSubscription: totalSubscription,
                            total: totalSubscription + totalSeparatedCredits
                          }
                        }) ?? []
                      }
                      columns={consumptionReportCols}
                      sx={{
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                          outline: "none !important",
                        },
                        minWidth: '100%'
                      }}
                    />
                  )
                  : null
              }
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  )
}