import { Box, Divider, FormControlLabel, Grid, List, ListItem, ListItemButton, ListItemText, Switch, Typography } from "@mui/material"
import { BusinessSizeKeys, IBusinessSize } from "../../../../../../application/models"
import { useAppSelector } from "../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../use-cases/project"
import { FilterHeader } from "../filter-header"
import { useState } from "react"
import { BusinessSizeFilterNotIncludePreview, BusinessSizeFilterPreview } from "./business-size-filter-preview"

export const businessSizeKeyToLabel = (businessSizeKey: IBusinessSize) => {
  switch (businessSizeKey) {
    case 'MEI':
      return 'MEI - Microempreendedor Individual'
    case 'MICRO':
      return 'Microempresa'
    case 'PEQUENA':
      return 'Pequena empresa'
    case 'MEDIA':
      return 'Média empresa'
    case 'GRANDE':
      return 'Grande empresa'
    default:
      return 'Não definido'
  }
}

export const BusinessSizeFilter = () => {
  // State
  const includeBusinessSizeFilter = useAppSelector((state) => state.project?.selectedFilter?.includeBusinessSize)
  const notIncludeBusinessSizeFilter = useAppSelector((state) => state.project.selectedFilter?.notIncludeBusinessSize)
  // Use State
  const [include, setInclude] = useState<boolean>(true)
  // Hook
  const { addIncludeBusinessSizeFilter, addNotIncludeBusinessSizeFilter } = useProjectService()

  return (
    <>
      <FilterHeader title="Porte" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
          Esta opção permite filtrar empresas através do porte em que ela se encontra na Receita Federal. Podendo ser marcado somente um único ou vários portes ao mesmo tempo.
        </Typography>
        <Typography>
          Exemplo: MEI / Pequena / Média
        </Typography>
        <Typography>
          Caso esta opção não seja marcada, serão considerados todos os portes na contagem.
        </Typography>
        <Grid item xs={12} py={3}>
          <Divider />
        </Grid>
      </>} />

      <Box p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              label={include ? 'Incluir Porte' : 'Não incluir Porte'}
              control={
                <Switch
                  checked={include}
                  onChange={(e) => {
                    setInclude(e.target.checked)
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <List>
              {
                BusinessSizeKeys.filter((businessSize) => {
                  if (includeBusinessSizeFilter?.includes(businessSize)) {
                    return false
                  }

                  if (notIncludeBusinessSizeFilter?.includes(businessSize)) {
                    return false
                  }

                  return businessSize
                }).map((businessSize, index) => {
                  return <>
                    <ListItem key={index}>
                      <ListItemButton
                        onClick={() => {
                          if (include) {
                            addIncludeBusinessSizeFilter({
                              businessSize: businessSize
                            })
                          } else {
                            addNotIncludeBusinessSizeFilter({
                              businessSize: businessSize
                            })
                          }
                        }}
                      >
                        <ListItemText
                          primary={businessSizeKeyToLabel(businessSize).toUpperCase()}
                          secondary={`Clique para adicionar à lista de ${include ? 'inclusão' : 'exclusão'} de portes`}
                        />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </>
                })
              }
            </List>
          </Grid>

          {
            (includeBusinessSizeFilter?.length ?? 0) > 0 &&
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight="bold" color="primary">
                Incluir portes:
              </Typography>
              <BusinessSizeFilterPreview />
            </Grid>
          }
          {
            (notIncludeBusinessSizeFilter?.length ?? 0) > 0 &&
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight="bold" color="secondary">
                Não incluir portes:
              </Typography>
              <BusinessSizeFilterNotIncludePreview />
            </Grid>
          }
        </Grid>

      </Box>
    </>
  )
}