import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useAppSelector } from '../../../../../application/states/hooks';
import { useEffect, useState, useRef } from 'react';
import { useProjectExportService } from '../../../../../use-cases/project-export';
import { debounce, ListItem, ListItemText } from '@mui/material';
import { formatCnpj } from '../../../../../utils';

export const CompaniesVirtualizedList = () => {
    // Service
    const { getVirtualizedCompaniesList, setTableFilterCompanyId } = useProjectExportService();
    // State
    const { companies } = useAppSelector((state) => state.projectExport);
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [companyOptions, setCompanyOptions] = useState<{
        companyId: string
        cnpj: string
        razaoSocial: string
    }[]>([])
    const [selectedCompany, setSelectedCompany] = useState<{
        companyId: string
        cnpj: string
        razaoSocial: string
    } | null>(null)

    const isFirstRender = useRef(true)
    const listRef = useRef<HTMLElement | null>(null)

    const fetchOptions = async (searchTerm: string, page: number) => {
        if (isLoading) return

        setIsLoading(true)
        await getVirtualizedCompaniesList({
            searchTerm: searchTerm,
            page: page,
        })
        setIsLoading(false)
    }

    useEffect(() => {
        if (!isFirstRender.current) {
            fetchOptions(searchTerm, page)
        } else {
            isFirstRender.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchTerm])

    useEffect(() => {
        setCompanyOptions([])
        setPage(1)
        setHasMore(true)
    }, [searchTerm])

    useEffect(() => {
        if (companies && companies.length > 0) {
            if (page === 1) {
                setCompanyOptions([...companies])
            } else {
                let newCompanyList = [...companyOptions]

                companies.forEach((company) => {
                    if (!newCompanyList.find((item) => item.companyId === company.companyId)) {
                        newCompanyList.push(company)
                    }
                })

                setCompanyOptions([...newCompanyList])
            }
            setHasMore(companies.length > 19)
        } else {
            setHasMore(false)
        }
    }, [companies, companyOptions, page])

    const handleScroll = debounce((event: React.SyntheticEvent) => {
        const target = event.target as HTMLElement
        const scrollThreshold = 5
        const scrollPosition = target.scrollTop + target.clientHeight
        const scrollHeight = target.scrollHeight

        const remainingItems = scrollHeight - scrollPosition
        const itemHeight = target.scrollHeight / companyOptions.length
        const remainingVisibleItems = Math.floor(remainingItems / itemHeight)

        if (remainingVisibleItems <= scrollThreshold && hasMore && !isLoading) {
            setPage((prevPage) => prevPage + 1)
        }
    }, 1000)

    return (
        <Autocomplete
            noOptionsText={companies.length === 0 && !hasMore ? "Não há empresas disponíveis" : "Buscando empresas..."}
            options={companyOptions.length > 0 ? companyOptions.sort((a, b) => -b.razaoSocial[0].localeCompare(a.razaoSocial[0])) : []}
            groupBy={(option) => option.razaoSocial[0]}
            getOptionLabel={(option) => option.razaoSocial.toUpperCase() || ''}
            getOptionKey={(option) => option.companyId}
            isOptionEqualToValue={(option, value) => option.companyId === value.companyId}
            value={selectedCompany}
            renderOption={(props, option) => {
                const { cnpj, razaoSocial, companyId } = option

                return (
                    <ListItem
                        {...props}
                        key={companyId}
                    >
                        <ListItemText primary={razaoSocial.toUpperCase()} secondary={`CNPJ: ${formatCnpj(cnpj)}`} />
                    </ListItem>
                )
            }}
            ListboxProps={{
                onScroll: handleScroll,
                ref: listRef
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Selecione a empresa"
                    value={searchTerm}
                    onChange={debounce((e) => setSearchTerm(e.target.value), 1000)}
                />
            )}
            onChange={(event, newValue) => {
                if (newValue) {
                    setTableFilterCompanyId({
                        companyId: newValue.companyId
                    })
                    setSelectedCompany(newValue)
                } else {
                    setSearchTerm('')
                    setSelectedCompany(null)
                    setTableFilterCompanyId({
                        companyId: ''
                    })
                }
            }}
        />
    )
}
