import { useCallback } from "react"
import { useAppDispatch } from "../../application/states/hooks"
import { setNaturezaJuridicaListState, setNaturezaJuridicaSearchTermState } from "../../application/states/natureza-juridica"
import { executeAlert } from "../../application/states/toast-alert"
import { useNaturezaJuridicaApi } from "../../infra/naturea-juridica-api/useNaturezaJuridicaApi"

export const useNaturezaJuridicaService = () => {
  const dispatch = useAppDispatch()
  const { findAllNaturezaJuridica } = useNaturezaJuridicaApi()
  const setNaturezaJuridicaList = useCallback(() => {
    findAllNaturezaJuridica().then((naturezaJuridicaList) => {
  
      dispatch(setNaturezaJuridicaListState({
        naturezaJuridicaList: naturezaJuridicaList
      }))
    }).catch(() => {
      dispatch(executeAlert({
        message: 'Erro ao buscar naturezaJuridica',
        type: 'error'
      }))
    })
  }, [dispatch, findAllNaturezaJuridica])

  const setSearchTerm = useCallback((data: {
    searchTerm: string
  }) => {
    dispatch(setNaturezaJuridicaSearchTermState({
      searchTerm: data.searchTerm
    }))
  }, [dispatch])


  return {
    setNaturezaJuridicaList,
    setSearchTerm
  }
}