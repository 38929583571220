// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #svg-map path { fill:#723989 }
    #svg-map text { fill:#fff; font:12px Arial-BoldMT, sans-serif; cursor:pointer }
    #svg-map  path:hover{ fill:#421d51 !important }
    #svg-map .circle { fill:#ee9338 }
    #svg-map path:hover .circle { fill:#003399 !important; cursor:pointer }

  
    .hover-container {
      position: relative;
    }

    .item-to-display {
      position: absolute;
      display:block;
      top: 50%;
      left: 50%;
      width: 300px;
      height: 400px;
      background: #000;
      opacity: 0;
      transition: opacity 0.5s;

    } */


.svg-map path {
  fill: #723989;
  stroke: #fff;
  stroke-width: 1;
  transition: 1s all;
}

.svg-map path:hover{
  fill: #341440;

}

`, "",{"version":3,"sources":["webpack://./src/presentation/pages/workspace/dashboard/brazil-map/brazil-map.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;OAsBO;;;AAGP;EACE,aAAa;EACb,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;;AAEf","sourcesContent":["/* #svg-map path { fill:#723989 }\n    #svg-map text { fill:#fff; font:12px Arial-BoldMT, sans-serif; cursor:pointer }\n    #svg-map  path:hover{ fill:#421d51 !important }\n    #svg-map .circle { fill:#ee9338 }\n    #svg-map path:hover .circle { fill:#003399 !important; cursor:pointer }\n\n  \n    .hover-container {\n      position: relative;\n    }\n\n    .item-to-display {\n      position: absolute;\n      display:block;\n      top: 50%;\n      left: 50%;\n      width: 300px;\n      height: 400px;\n      background: #000;\n      opacity: 0;\n      transition: opacity 0.5s;\n\n    } */\n\n\n.svg-map path {\n  fill: #723989;\n  stroke: #fff;\n  stroke-width: 1;\n  transition: 1s all;\n}\n\n.svg-map path:hover{\n  fill: #341440;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
