import { List, Grid, Chip, Avatar } from "@mui/material"
import { theme } from "../../../../../../styles"
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { useAppSelector } from "../../../../../../../application/states/hooks";
import { useProjectService } from "../../../../../../../use-cases/project";

export const BusinessSizeFilterPreview = () => {
  // State
  const includeBusinessSizeFilter = useAppSelector((state) => state.project.selectedFilter?.includeBusinessSize)
  // Hook
  const { removeIncludeBusinessSizeFilter } = useProjectService()

  return <>
    <List>
      <Grid container spacing={3}>
        {includeBusinessSizeFilter?.map((businessSize, index) => {
          return (
            <Grid item key={index}>
              <Chip
                label={businessSize.toUpperCase()}
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    transition: '0.1s all',
                    display: 'block',
                    whiteSpace: 'normal',
                    padding: '10px 15px',
                    fontWeight: 'bold'
                  }
                }}
                color="primary"
                onDelete={() => {
                  removeIncludeBusinessSizeFilter({
                    businessSize: businessSize
                  })
                }}
                avatar={
                  <Avatar
                    style={{
                      cursor: 'pointer',
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText
                    }}
                  >
                    <DomainAddIcon sx={{
                      "&": {
                        transition: '0.1s all'
                      },
                      "&:hover": {
                        color: "secondary.main"
                      }
                    }} />
                  </Avatar>
                }
              />
            </Grid>
          )
        })}
      </Grid>
    </List >
  </>
}