import { Box, Button, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography, useTheme } from "@mui/material"
import GridOnIcon from '@mui/icons-material/GridOn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FactoryIcon from '@mui/icons-material/Factory';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import GradingIcon from '@mui/icons-material/Grading';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { useAppSelector } from "../../../../../application/states/hooks";
import { useProjectService } from "../../../../../use-cases/project";
import { LocationFilter } from "./location-filter";
import { SectorFilter } from "./sector-filter";
import { SegmentosFilter } from "./segmentos-filter";
import { EconomicActivityFilter } from "./economic-activity-filter";
import { BusinessSizeFilter } from "./business-size-filter";
import { RevenuesFilter } from "./revenues-filter";
import { EmployeeFilter } from "./employee-filter";
import { BusinessAssociateFilter } from "./business-associates-filter";
import { StatusFilter } from "./status-filter";
import { EconomicGroupFilter } from "./economic-group-filter";
import { CreatedAtFilter } from "./created-at-filter";
import { ContactFilter } from "./contact-filter";
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { SpecialStatusFilter } from "./special-status-filter";
import { ProjectReportResume } from "../project-report-resume";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { BlackListFilter } from "./blacklist-filter";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { TermsFilter } from "./terms-filter";
import { RegimeTributarioFilter } from "./regime-tributario-filter";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { darkenColor } from "../../../../../utils";
import { ShareCapitalFilter } from "./share-capital-filter";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { FinancialIssuesFilter } from "./financial-issues";
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import { GeolocationFilter } from "./geolocation-filter";
import GavelIcon from '@mui/icons-material/Gavel';
import { NaturezaJuridicaFilter } from "./natureza-juridica-filter";
export const FiltersSection = () => {
  const projectReport = useAppSelector((state) => state.project.projectReport)

  const theme = useTheme()
  const activeFilter = useAppSelector((state) => state.project.activeFilter)
  const { setActiveFilter, resetFilters } = useProjectService()
  return (
    <>
      <Box
        style={{
          marginBottom: '80px'
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <List>
              <ListItem key={0}>
                <Paper elevation={3}
                  style={{
                    width: '100%'
                  }}
                >
                  <ListItemButton onClick={() => {
                    setActiveFilter({
                      activeFilter: 0
                    })
                  }} style={{
                    background: activeFilter === 0 ? theme.palette.primary.main : '',
                    color: activeFilter === 0 ? 'white' : 'black',
                  }}>
                    <ListItemIcon>
                      <LocationOnIcon style={{
                        color: activeFilter === 0 ? 'white' : 'black',
                      }} />
                    </ListItemIcon>
                    <ListItemText primary="LOCALIZAÇÃO / UF / CIDADE" />
                  </ListItemButton>
                </Paper>
              </ListItem>

              <ListItem key={18}>
                <Paper elevation={3}
                  style={{
                    width: '100%'
                  }}
                >
                  <ListItemButton onClick={() => {
                    setActiveFilter({
                      activeFilter: 18
                    })
                  }} style={{
                    background: activeFilter === 18 ? theme.palette.primary.main : '',
                    color: activeFilter === 18 ? 'white' : 'black',
                  }}>
                    <ListItemIcon>
                      <TravelExploreIcon style={{
                        color: activeFilter === 18 ? 'white' : 'black',
                      }} />
                    </ListItemIcon>
                    <ListItemText primary="GEOLOCALIZAÇÃO" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={1}>
                <Paper elevation={3}
                  style={{
                    width: '100%'
                  }}
                >
                  <ListItemButton onClick={() => {
                    setActiveFilter({
                      activeFilter: 1
                    })
                  }} style={{
                    background: activeFilter === 1 ? theme.palette.primary.main : '',
                    color: activeFilter === 1 ? 'white' : 'black',
                  }}>
                    <ListItemIcon>
                      <FactoryIcon style={{
                        color: activeFilter === 1 ? 'white' : 'black',
                      }} />
                    </ListItemIcon>
                    <ListItemText primary="SETOR ECONÔMICO" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={2}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 2
                      })
                    }}
                    style={{
                      background: activeFilter === 2 ? theme.palette.primary.main : '',
                      color: activeFilter === 2 ? 'white' : 'black',
                    }}
                  >
                    <ListItemIcon>
                      <GridOnIcon
                        style={{
                          color: activeFilter === 2 ? 'white' : 'black',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="SEGMENTOS / GRUPOS" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={3}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 3
                      })
                    }}
                    style={{
                      background: activeFilter === 3 ? theme.palette.primary.main : '',
                      color: activeFilter === 3 ? 'white' : 'black',
                    }}

                  >
                    <ListItemIcon>
                      <ApartmentIcon
                        style={{
                          color: activeFilter === 3 ? 'white' : 'black',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="ATIVIDADE ECONÔMICA / CNAE" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={4}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 4
                      })
                    }}
                    style={{
                      background: activeFilter === 4 ? theme.palette.primary.main : '',
                      color: activeFilter === 4 ? 'white' : 'black',
                    }}
                  >
                    <ListItemIcon>
                      <DomainAddIcon
                        style={{
                          color: activeFilter === 4 ? 'white' : 'black',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="PORTE DA EMPRESA" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={5}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 5
                      })
                    }}
                    style={{
                      background: activeFilter === 5 ? theme.palette.primary.main : '',
                      color: activeFilter === 5 ? 'white' : 'black',
                    }}

                  >
                    <ListItemIcon
                      style={{
                        color: activeFilter === 5 ? 'white' : 'black',
                      }}
                    >
                      <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary="FATURAMENTO" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={6}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 6
                      })
                    }}
                    style={{
                      background: activeFilter === 6 ? theme.palette.primary.main : '',
                      color: activeFilter === 6 ? 'white' : 'black',
                    }}
                  >
                    <ListItemIcon>
                      <Diversity3Icon
                        style={{
                          color: activeFilter === 6 ? 'white' : 'black',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="NUM. FUNCIONÁRIOS" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={7}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 7
                      })
                    }}
                    style={{
                      background: activeFilter === 7 ? theme.palette.primary.main : '',
                      color: activeFilter === 7 ? 'white' : 'black',
                    }}
                  >
                    <ListItemIcon
                      style={{
                        color: activeFilter === 7 ? 'white' : 'black',
                      }}
                    >
                      <ConnectWithoutContactIcon />
                    </ListItemIcon>
                    <ListItemText primary="NUM. SÓCIOS" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={8}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton onClick={() => {
                    setActiveFilter({
                      activeFilter: 8
                    })
                  }} style={{
                    background: activeFilter === 8 ? theme.palette.primary.main : '',
                    color: activeFilter === 8 ? 'white' : 'black',
                  }}>
                    <ListItemIcon>
                      <CurrencyExchangeIcon style={{
                        color: activeFilter === 8 ? 'white' : 'black',
                      }} />
                    </ListItemIcon>
                    <ListItemText primary="CAPITAL SOCIAL" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={9}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 9
                      })
                    }}
                    style={{
                      background: activeFilter === 9 ? theme.palette.primary.main : '',
                      color: activeFilter === 9 ? 'white' : 'black',
                    }}
                  >
                    <ListItemIcon>
                      <DeviceHubIcon
                        style={{
                          color: activeFilter === 9 ? 'white' : 'black',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="MATRIZ / FILIAL" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={10}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 10
                      })
                    }}
                    style={{
                      background: activeFilter === 10 ? theme.palette.primary.main : '',
                      color: activeFilter === 10 ? 'white' : 'black',
                    }}

                  >
                    <ListItemIcon>
                      <GradingIcon
                        style={{
                          color: activeFilter === 10 ? 'white' : 'black',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="SITUAÇÃO CADASTRAL / ATIVAS" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={11}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 11
                      })
                    }}
                    style={{
                      background: activeFilter === 11 ? theme.palette.primary.main : '',
                      color: activeFilter === 11 ? 'white' : 'black',
                    }}
                  >
                    <ListItemIcon>
                      <CalendarMonthIcon
                        style={{
                          color: activeFilter === 11 ? 'white' : 'black',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="DATA DE ABERTURA" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={12}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton onClick={() => {
                    setActiveFilter({
                      activeFilter: 12
                    })
                  }} style={{
                    background: activeFilter === 12 ? theme.palette.primary.main : '',
                    color: activeFilter === 12 ? 'white' : 'black',
                  }}>
                    <ListItemIcon>
                      <TextFieldsIcon style={{
                        color: activeFilter === 12 ? 'white' : 'black',
                      }} />
                    </ListItemIcon>
                    <ListItemText primary="PALAVRAS CHAVE" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={13}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton onClick={() => {
                    setActiveFilter({
                      activeFilter: 13
                    })
                  }} style={{
                    background: activeFilter === 13 ? theme.palette.primary.main : '',
                    color: activeFilter === 13 ? 'white' : 'black',
                  }}>
                    <ListItemIcon>
                      <MoneyOffIcon style={{
                        color: activeFilter === 13 ? 'white' : 'black',
                      }} />
                    </ListItemIcon>
                    <ListItemText primary="PENDÊNCIAS FINANCEIRAS" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={14}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 14
                      })
                    }}
                    style={{
                      background: activeFilter === 14 ? theme.palette.primary.main : '',
                      color: activeFilter === 14 ? 'white' : 'black',
                    }}

                  >
                    <ListItemIcon>
                      <FindInPageIcon
                        style={{
                          color: activeFilter === 14 ? 'white' : 'black',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="SITUAÇÃO ESPECIAL" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={15}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 15
                      })
                    }}
                    style={{
                      background: activeFilter === 15 ? theme.palette.primary.main : '',
                      color: activeFilter === 15 ? 'white' : 'black',
                    }}

                  >
                    <ListItemIcon
                      style={{
                        color: activeFilter === 15 ? 'white' : 'black',
                      }}
                    >
                      <PointOfSaleIcon />
                    </ListItemIcon>
                    <ListItemText primary="REGIME TRIBUTÁRIO" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={19}>
                <Paper elevation={3}
                  style={{
                    width: '100%'
                  }}
                >
                  <ListItemButton onClick={() => {
                    setActiveFilter({
                      activeFilter: 19
                    })
                  }} style={{
                    background: activeFilter === 19 ? theme.palette.primary.main : '',
                    color: activeFilter === 19 ? 'white' : 'black',
                  }}>
                    <ListItemIcon>
                      <GavelIcon  style={{
                        color: activeFilter === 19 ? 'white' : 'black',
                      }} />
                    </ListItemIcon>
                    <ListItemText primary="NATUREZA JURÍDICA" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={16}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 16
                      })
                    }}
                    style={{
                      background: activeFilter === 16 ? theme.palette.primary.main : '',
                      color: activeFilter === 16 ? 'white' : 'black',
                    }}
                  >
                    <ListItemIcon>
                      <LocalPhoneIcon
                        style={{
                          color: activeFilter === 16 ? 'white' : 'black',
                        }}

                      />
                    </ListItemIcon>
                    <ListItemText primary="CONTATOS" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem key={17}>
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton
                    onClick={() => {
                      setActiveFilter({
                        activeFilter: 17
                      })
                    }}
                    style={{
                      background: activeFilter === 17 ? theme.palette.primary.main : '',
                      color: activeFilter === 17 ? 'white' : 'black',
                    }}
                  >
                    <ListItemIcon>
                      <ContentPasteOffIcon
                        style={{
                          color: activeFilter === 17 ? 'white' : 'black',
                        }}

                      />
                    </ListItemIcon>
                    <ListItemText primary="DEDUPLICAÇÃO" />
                  </ListItemButton>
                </Paper>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <Box p={2}>

              {
                activeFilter === 0 ? (
                  <>
                    <LocationFilter />
                  </>
                ) : null
              }
              {
                activeFilter === 18 ? (
                  <>
                    <GeolocationFilter />
                  </>
                ) : null
              }
              {
                activeFilter === 1 ? (
                  <>
                    <SectorFilter />
                  </>
                ) : null

              }
              {
                activeFilter === 2 ? (
                  <>
                    <SegmentosFilter />
                  </>
                ) : null
              }
              {
                activeFilter === 3 ? (
                  <>
                    <EconomicActivityFilter />
                  </>
                ) : null
              }
              {
                activeFilter === 4 ? (
                  <>
                    <BusinessSizeFilter />
                  </>
                ) : null
              }
              {
                activeFilter === 5 ? (
                  <>
                    <RevenuesFilter />
                  </>
                ) : null
              }
              {
                activeFilter === 6 ? (
                  <>
                    <EmployeeFilter />
                  </>
                ) : null
              }
              {
                activeFilter === 7 ? (
                  <BusinessAssociateFilter />
                ) : null
              }
              {
                activeFilter === 8 ? (
                  <ShareCapitalFilter />
                ) : null
              }
              {
                activeFilter === 9 ? (
                  <EconomicGroupFilter />
                ) : null
              }
              {
                activeFilter === 10 ? (
                  <StatusFilter />
                ) : null
              }
              {
                activeFilter === 11 ? (
                  <CreatedAtFilter />
                ) : null
              }
              {
                activeFilter === 12 ? (
                  <TermsFilter />
                ) : null
              }
              {
                activeFilter === 13 ? (
                  <FinancialIssuesFilter />
                ) : null
              }
              {
                activeFilter === 14 ? (
                  <SpecialStatusFilter />
                ) : null
              }
              {
                activeFilter === 15 ? (
                  <RegimeTributarioFilter />
                ) : null
              }
              {
                activeFilter === 16 ? (
                  <ContactFilter />
                ) : null
              }
              {
                activeFilter === 17 ? (
                  <BlackListFilter />
                ) : null
              }
              {
                activeFilter === 19 && (
                  <NaturezaJuridicaFilter />
                )
              }
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box m={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      resetFilters()
                    }}
                    sx={{
                      '&:hover': {
                        backgroundColor: darkenColor(theme.palette.primary.main, 10)
                      }
                    }}
                  >
                    Redefinir filtros
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {
                    projectReport ? (
                      <>
                        <ProjectReportResume />
                      </>
                    ) : <>
                      <Typography variant="h6" component="h6" style={{
                        textAlign: 'center',
                        marginTop: '20px'
                      }}>
                        Para gerar um relatório, clique no botão "Atualizar".
                      </Typography>
                    </>
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}