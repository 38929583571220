import axios from 'axios'
import { useMemo } from 'react'
import { useAppSelector } from '../../application/states/hooks'
import { useLocalStorageService } from '../../use-cases/local-storage'
import { useNavigationService } from '../../use-cases/navigation'

export const useAxiosInstances = () => {
  const authState = useAppSelector((state) => state.auth)
  const isValid = useAppSelector((state) => state.auth.isValid)
  const { navigateTo } = useNavigationService()
  const { removeItem } = useLocalStorageService()

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: process.env.REACT_APP_BASE_URL
    })
  }, [])
  
  const axiosAuthInstance = useMemo(() => {
    const instance = axios.create({
      headers: {
        Authorization: `bearer ${authState.token}`,
      },
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 600000
    })

    instance.interceptors.response.use((response) => {
      return response
    }
      , (error) => {
        if (error?.response?.status === 401) {
          if (isValid !== undefined) {
            removeItem({
              key: 'ACCESS_TOKEN'
            })
            navigateTo({
              route: '/authentication/login'
            })
          }
        }
        return Promise.reject(error)
      }
    )
    return instance
  }, [authState.token, navigateTo, isValid, removeItem])
  return {
    axiosInstance,
    axiosAuthInstance
  }
}