import { List, Grid, Chip, Avatar } from "@mui/material"
import { theme } from "../../../../../../styles"
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { useAppSelector } from "../../../../../../../application/states/hooks";
import { useProjectService } from "../../../../../../../use-cases/project";

export const BusinessSizeFilterNotIncludePreview = () => {
  // State
  const notIncludeBusinessSizeFilter = useAppSelector((state) => state.project.selectedFilter?.notIncludeBusinessSize)
  // Hook
  const { removeNotIncludeBusinessSizeFilter } = useProjectService()

  return <>
    <List>
      <Grid container spacing={3}>
        {notIncludeBusinessSizeFilter?.map((businessSize, index) => {
          return (
            <Grid item key={index}>
              <Chip
                label={businessSize.toUpperCase()}
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    transition: '0.1s all',
                    display: 'block',
                    whiteSpace: 'normal',
                    padding: '10px 15px',
                    fontWeight: 'bold'
                  }
                }}
                color="secondary"
                onDelete={() => {
                  removeNotIncludeBusinessSizeFilter({
                    businessSize: businessSize
                  })
                }}
                avatar={
                  <Avatar
                    style={{
                      cursor: 'pointer',
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.secondary.contrastText
                    }}
                  >
                    <DomainAddIcon
                      sx={{
                        "&": {
                          transition: '0.1s all'
                        },
                        "&:hover": {
                          color: "primary.main"
                        }
                      }}
                    />
                  </Avatar>
                }
              />
            </Grid>
          )
        })}
      </Grid>
    </List >
  </>
}