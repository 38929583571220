import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INaturezaJuridica } from '../../models/INaturezaJuridica'



interface INaturezaJuridicaState {
  searchTerm: string
  naturezaJuridicaList: INaturezaJuridica[]
}

const initialState: INaturezaJuridicaState = {
  searchTerm: '',
  naturezaJuridicaList: []
};

const naturezaJuridicaSlice = createSlice({
  name: 'natureza-juridica',
  initialState,
  reducers: {
    setNaturezaJuridicaListState(state, action: PayloadAction<{
      naturezaJuridicaList: INaturezaJuridica[]
    }>) {
      state.naturezaJuridicaList = action.payload.naturezaJuridicaList
    },
    setNaturezaJuridicaSearchTermState(state, action: PayloadAction<{
      searchTerm: string
    }>) {
      state.searchTerm = action.payload.searchTerm
    }
  },
});

export const {
  setNaturezaJuridicaListState,
  setNaturezaJuridicaSearchTermState
} = naturezaJuridicaSlice.actions;
export const naturezaJuridicaReducer = naturezaJuridicaSlice.reducer;