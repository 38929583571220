import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControlLabel, Grid, Switch, TextField } from "@mui/material"
import { useProjectService } from "../../../../use-cases/project";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../application/states/hooks";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { ProjectListTable } from "./project-card";
import { theme } from "../../../styles";
import { darkenColor } from "../../../../utils";
import { useUserService } from "../../../../use-cases/user";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { debounce } from '@mui/material/utils';

export const ProjectListPage = () => {
  // States
  const { active } = useAppSelector((state) => state.project)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
  const { company } = useAppSelector((state) => state.company)
  const createProjectFormOpen = useAppSelector((state) => state.project.createProjectForm.open)
  const projectList = useAppSelector((state) => state.project.projectList)
  const projectName = useAppSelector((state) => state.project.createProjectForm.name)
  const projectNameFieldProps = useAppSelector((state) => state.project.createProjectForm.fields.name)
  const formAction = useAppSelector((state => state.project.createFormAction))
  const projectToClone = useAppSelector((state => state.project.projectToClone))
  const { userList } = useAppSelector((state) => state.user)
  const [selectedOption, setSelectedOption] = useState<number | null>(null)
  const { tableFilters } = useAppSelector((state) => state.project)
  let options = [
    {
      label: 'Nome do Projeto',
      id: 1
    },
    {
      label: 'Usuário',
      id: 2
    },
    {
      label: 'Data de Criação',
      id: 3
    }
  ]

  options = [...options].sort((a,b) => a.label.localeCompare(b.label))

  // Services
  const {
    createProject,
    setProjectName,
    openCreateProjectForm,
    closeCreateProjectForm,
    cloneProject,
    updateProjectName,
    setProjectFilterActive,
    setProjectList,
    resetTableFilters,
    setTableFilterName,
    setTableFilterCreatedBy,
    setTableFilterStartDate,
    setTableFilterEndDate
  } = useProjectService()

  const { setUserList } = useUserService()

  useEffect(() => {
    setProjectList()
  }, [setProjectList])

  useEffect(() => {
    setUserList()
  }, [setUserList])

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Projetos`
  }, [resaleConfig])

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={
        createProjectFormOpen
      } onClose={() => {
        closeCreateProjectForm()
      }}>
        <DialogTitle>
          {
            formAction === 'CREATE' ? 'Criar projeto' : ''
          }
          {
            formAction === 'CLONE' ? 'Duplicar projeto' : ''
          }
          {
            formAction === 'EDIT' ? 'Editar projeto' : ''
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escolha um nome para o projeto
          </DialogContentText>
          <Box my={3}>
            <TextField
              error={!projectNameFieldProps.isValid}
              helperText={!projectNameFieldProps.isValid && `${projectNameFieldProps.error?.message}`}
              value={projectName}
              onChange={(e) => {
                setProjectName({
                  name: e.target.value
                })
              }}
              autoFocus
              id="nomeproj"
              label="Digite o nome do projeto"
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            closeCreateProjectForm()
          }}>Cancelar</Button>
          <Button onClick={() => {
            if (formAction === 'CREATE') {
              createProject()
            } else if (formAction === 'CLONE') {
              cloneProject({
                projectId: projectToClone ?? ''
              })
            } else if (formAction === 'EDIT') {
              updateProjectName({
                name: projectName,
                projectId: projectToClone ?? ''
              })
            }
          }}>
            {
              formAction === 'CREATE' ? 'Criar' : ''
            }
            {
              formAction === 'CLONE' ? 'Duplicar' : ''
            }
            {
              formAction === 'EDIT' ? 'Editar' : ''
            }
          </Button>
        </DialogActions>
      </Dialog>
      <Fab
        onClick={() => {
          openCreateProjectForm({
            action: 'CREATE'
          })
          setProjectName({
            name: ''
          })
        }}
        style={{
          position: 'fixed',
          bottom: '5%',
          right: '5%'
        }}
        color="primary"
        aria-label="add"
        sx={{
          '&:hover': {
            backgroundColor: darkenColor(theme.palette.primary.main, 10)
          }
        }}
      >
        <AddIcon style={{
          color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
        }} />
      </Fab>
      <Box p={3}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <ProjectsFilterForm />
          </Grid> */}
          {/* <Grid item xs={12}>
            <Divider />
          </Grid> */}
          <Grid item xs={4}>
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              defaultValue={options.find((option) => option.id === selectedOption)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filtros"
                />
              )}
              onChange={(event, newValue) => {
                resetTableFilters()
                if (newValue) {
                  setSelectedOption(newValue.id)
                } else {
                  setSelectedOption(null)
                  resetTableFilters()
                }
              }}
            />
          </Grid>
          <Grid item xs={8}>
            {
              selectedOption === 1
              && <TextField
                onChange={debounce((e) => {
                  setTableFilterName({
                    name: e.target.value
                  })
                }, 1000)}
                fullWidth
                id="search"
                label="Pesquisar pelo nome do projeto"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <SearchIcon />
                  )
                }}
              />
            }
            {
              selectedOption === 2
              && <Autocomplete
                options={userList.map((user) => {
                  return {
                    name: user.name,
                    id: user.id
                  }
                })}
                getOptionLabel={(user) => user.name.toUpperCase()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                defaultValue={userList.find((user) => user.id === tableFilters.createdBy)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Usuários"
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setTableFilterCreatedBy({
                      createdBy: newValue.id
                    })
                  } else {
                    resetTableFilters()
                  }
                }}
              />
            }
            {
              selectedOption === 3
              && <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={ptBR}
                    >
                      <DatePicker
                        disableFuture
                        value={tableFilters.startDate ? tableFilters.startDate : null}
                        onChange={debounce((newValue: Date | null, context) => {
                          if (context.validationError !== 'invalidDate') {
                            if (newValue) {
                              setTableFilterStartDate({
                                startDate: newValue
                              })
                            } else {
                              setTableFilterStartDate({
                                startDate: undefined
                              })
                            }
                          }
                        }, 1000)}
                        label="A partir de"
                        slotProps={{
                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                '&:hover': {
                                  backgroundColor: darkenColor(theme.palette.primary.main, 10)
                                }
                              }
                            }
                          },
                          textField: { fullWidth: true }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={ptBR}
                    >
                      <DatePicker
                        disableFuture
                        value={tableFilters.endDate ? tableFilters.endDate : null}
                        onChange={debounce((newValue: Date | null, context) => {
                          if (context.validationError !== 'invalidDate') {
                            if (newValue) {
                              setTableFilterEndDate({
                                endDate: newValue
                              })
                            } else {
                              setTableFilterEndDate({
                                endDate: undefined
                              })
                            }
                          }
                        }, 1000)}
                        label="Até"
                        slotProps={{
                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                '&:hover': {
                                  backgroundColor: darkenColor(theme.palette.primary.main, 10)
                                }
                              }
                            }
                          },
                          textField: { fullWidth: true }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </>
            }
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={
              <Switch
                checked={!active}
                onChange={(e) => {
                  setProjectFilterActive({
                    active: e.target.checked
                  })
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            } label={'Mostrar projetos arquivados'} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ProjectListTable {...{
              projectList: projectList
            }} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}