// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*::-webkit-scrollbar,
* *::-webkit-scrollbar {
  height: 6px !important;
  width: 6px !important;
}`, "",{"version":3,"sources":["webpack://./src/presentation/assets/css/scroll.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;EACtB,qBAAqB;AACvB","sourcesContent":["*::-webkit-scrollbar,\n* *::-webkit-scrollbar {\n  height: 6px !important;\n  width: 6px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
