import { useCallback } from "react"
import { ICanceledProjectExport, IProjectExport } from "../../application/models"
import { useAxiosInstances } from "../axios-instance"

export const useProjectExportApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const findProjectExportByUserIdApi = useCallback((data: {
    userId: string;
    limit?: number;
    page?: number;
    filters?: {
      name?: string
      companyId?: string
      createdBy?: string
      startDate?: Date
      endDate?: Date
      type?: string
    };
  }): Promise<{
    projectExportList: {
      projectExport: IProjectExport
      fileExists: boolean
      canceledProjectExport?: ICanceledProjectExport
    }[]
    total: number
    page: number
  }> => {
    return axiosAuthInstance.get('/project-export/company-export', {
      params: {
        userId: data.userId,
        limit: data.limit,
        page: data.page,
        name: data.filters?.name,
        companyId: data.filters?.companyId,
        createdBy: data.filters?.createdBy,
        startDate: data.filters?.startDate,
        endDate: data.filters?.endDate,
        type: data.filters?.type
      }
    }).then((response) => {
      const result = response.data.result
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    }).catch((err) => {
      throw err
    })
  }, [axiosAuthInstance])

  /* This method will download the file using s */
  /* The endpoint use streams to download the file */

  const downloadProjectExportApi = useCallback((data: {
    projectExportId: string,
    exportLayoutId: string,
    fileExt: string
  }): Promise<any> => {
    return axiosAuthInstance.get('/project-export/download', {
      params: {
        ...data
      }
    }).then((response) => {
      const result = response.data.result
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    }).catch((err) => {
    })
  }, [axiosAuthInstance])

  const checkExportFile = useCallback((data: {
    projectExportId: string,
  }): Promise<boolean> => {
    return axiosAuthInstance.get('/project-export/check-file', {
      params: {
        ...data
      }
    }).then((response) => {
      const result = response.data.result.exists
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    }).catch((err) => {
    })
  }, [axiosAuthInstance])

  const recreateExportFileApi = useCallback((data: {
    projectExportId: string,
  }): Promise<boolean> => {
    return axiosAuthInstance.put('/project-export/recreate-file', {
      ...data
    }).then((response) => {
      const result = response.data.result.exists
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    }).catch((err) => {
    })
  }, [axiosAuthInstance])

  const getExportStatusApi = useCallback((data: {
    projectExportId: string,
  }): Promise<{
    status: 'pending' | 'completed' | 'failed' | 'processing',
    progress?: number
  }> => {

    return axiosAuthInstance.get('/project-export/status', {
      params: {
        ...data
      }
    }).then((response) => {
      const result = response.data.result
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    })
  }, [
    axiosAuthInstance
  ])

  const createCanceledProjectExportApi = useCallback((data: {
    resourceId: string
    whoCanceled: string
  }): Promise<Partial<ICanceledProjectExport>> => {
    return axiosAuthInstance.post('/cancel-project-export', data).then((response) => {
      const result = response.data.result

      if (result) {
        return result
      } else {
        throw new Error('Falha ao cancelar extração')
      }
    })
  }, [axiosAuthInstance])

  return {
    findProjectExportByUserIdApi,
    downloadProjectExportApi,
    checkExportFile,
    recreateExportFileApi,
    getExportStatusApi,
    createCanceledProjectExportApi
  }
}